import { Fundraiser, useFundraiser } from "../../data/fundraiser-service";
import clsx from 'clsx';
import styles from './analytics.module.scss';
import { DonationStatus, useDonations } from "../../data/donation-service";
import { useMapping } from "../../hooks/use-mapping";
import { PriceLabel } from "../labels/price-label";

export const FundraiserProgressBar = ({
  fundraiser,
  participantId,
}: {
  fundraiser: Fundraiser;
  participantId?: string;
}) => {
  const donations = useDonations({
    fundraiserId: fundraiser.id,
    participantId,
    statusIn: [
      DonationStatus.Pending,
      DonationStatus.Success,
    ],
  });
  // const fundraiser = useFundraiser(fundraiserId);

  const personalRaised = useMapping(() => {
    if (!donations.value) return 0;

    return donations.value.rows.map(x => x.amount).reduce((sum, x) => sum + x, 0);
  }, [ donations.value ]);

  const raisedAmount = useMapping(() => {
    if (participantId) return personalRaised;
    return fundraiser.donationsTotal + (fundraiser.pendingDonations || 0);
  }, [ personalRaised, fundraiser.donationsTotal, fundraiser.pendingDonations, participantId, donations.value ]);

  const personalDonations = (() => {
    if (participantId === undefined) return null;

    return <div className={clsx(styles.flex, styles.center, styles.smallText, styles.noGap)}>
      <div>
        Participant Donations
      </div>
    </div>
  })();

  const raiseGoal = useMapping(() => {
    if (participantId) return fundraiser.participantGoal;
    else return fundraiser.donationsGoal;
  }, [ fundraiser.donationsGoal, fundraiser.participantGoal, participantId ]);

  const percentDone = useMapping(() => {
    return Math.floor(raisedAmount / raiseGoal * 100);
  }, [ raisedAmount, raiseGoal ]);

  return <div className={clsx(styles.container, styles.flex, styles.center, styles.progress)}>
    <div className={clsx(styles.flexRow, styles.center, styles.justifyBetween, styles.smallText, styles.noGap)}>
      <div>
        <PriceLabel price={raisedAmount} /> ({percentDone}%)
      </div>
      <div>
        <PriceLabel price={raiseGoal} />
      </div>
    </div>
    <div className={clsx(styles.flexRow)}>
      <div className={styles.bar}>
        <div style={{ width: `${percentDone}%` }} className={styles.progressBar} ></div>
      </div>
    </div>
    {personalDonations}
  </div>
}