import { Link } from "react-router-dom";
import { Fundraiser } from "../../data/fundraiser-service"
import styles from './lists.module.scss';
import clsx from 'clsx';
import { PriceLabel } from "../labels/price-label";
import { useDonations } from "../../data/donation-service";
import { useMapping } from "../../hooks/use-mapping";

const PersonalDonations = ({
  fundraiserId,
  participantId,
}: {
  fundraiserId: string;
  participantId: string;
}) => {
  const donations = useDonations({
    fundraiserId,
    participantId,
  });

  const totalAmount = useMapping(() => {
    if (!donations.value) return 0;
    return donations.value.rows.map(x => x.amount).reduce((sum, x) => sum + x, 0);
  }, [ donations.value ]);

  return <div className={clsx(styles.flex, styles.center, styles.smallText, styles.noGap)}>
    <div>
      Personal Donations
    </div>
    <div>
      <PriceLabel price={totalAmount} />
    </div>
  </div>
}

const FundraiserListItem = ({
  adminLinks,
  fundraiser,
  contributionsParticipantId,
}: {
  adminLinks: boolean;
  fundraiser: Fundraiser;
  contributionsParticipantId?: string;
}) => {
  const personalDonations = useMapping(() => {
    if (contributionsParticipantId === undefined) return null;
    return <PersonalDonations fundraiserId={fundraiser.id} participantId={contributionsParticipantId} />
  }, [ contributionsParticipantId ]);

  const donations = (() => {
    const totalDonations = (fundraiser.donationsTotal || 0) + (fundraiser.pendingDonations || 0);
    const percentDone = Math.floor(totalDonations / fundraiser.donationsGoal * 100);

    return <div className={clsx(styles.flex, styles.center, styles.progress)}>
      <div className={clsx(styles.flexRow, styles.center, styles.justifyBetween, styles.smallText, styles.noGap)}>
        <div>
          <PriceLabel price={totalDonations} />
          <span> ({percentDone}%)</span>
        </div>
        <div>
          <PriceLabel price={fundraiser.donationsGoal} />
        </div>
      </div>
      <div className={clsx(styles.flexRow)}>
        <div className={styles.bar}>
          <div style={{ width: `${percentDone}%` }} className={styles.progressBar} ></div>
        </div>
      </div>
      {personalDonations}
    </div>
  })();

  return <Link to={`/fundraisers/${fundraiser.id}`}>
    <li className={clsx(styles.listItem, styles.clickable)}>
      <div>
        <div className={styles.itemRow}>
          <div>{fundraiser.title}</div>
          <div className={styles.statusLabel}>{fundraiser.status}</div>
        </div>
        <div className={styles.itemRow}>
          <div className={styles.date}>Updated on {new Date(fundraiser.updatedAt).toDateString()}</div>
        </div>
      </div>
      {donations}
    </li>
  </Link>
}

export const FundraiserList = ({
  fundraisers,
  contributionsParticipantId,
  adminLinks,
}: {
  fundraisers: Fundraiser[];
  contributionsParticipantId?: string;
  adminLinks: boolean;
}) => {
  const items = fundraisers.map(fundraiser => {
    return <FundraiserListItem adminLinks={adminLinks} fundraiser={fundraiser} contributionsParticipantId={contributionsParticipantId} />
  });

  return <ul className={styles.list}>
    {items}
  </ul>
}