export enum ContributionStatus {
  Pending = 'pending',
  Paid = 'paid',
}

export type Contribution = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: ContributionStatus;
  senderName: string;
  centAmount: number;
  fundraiserName: string;
}

export type ContributionQueryParams = {
  fundraiserId?: string;
}

const rows = [
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Pending,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Amy',
    centAmount: 100 * 100,
  },
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Paid,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Joe',
    centAmount: 100 * 100,
  },
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Paid,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Joe',
    centAmount: 100 * 100,
  },
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Paid,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Joe',
    centAmount: 100 * 100,
  },
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Paid,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Joe',
    centAmount: 100 * 100,
  },
  {
    id: '0',
    fundraiserName: "Tennis Fundraiser",
    status: ContributionStatus.Paid,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    senderName: 'Joe',
    centAmount: 100 * 100,
  },
];
export const useContributions = (query: ContributionQueryParams={}): Contribution[] => {
  let _rows = [ ...rows ];

  return _rows;
}