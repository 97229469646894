import { AccountQueryParams, useAccounts } from "../../data/account-service"
import { AccountList } from "../lists/account-list";
import styles from './sections.module.scss';

export const AccountsListSection = ({
  query,
}: {
  query: AccountQueryParams;
}) => {
  const accounts = useAccounts(query);

  return <section>
    <div>
      <AccountList accounts={accounts.value || []} />
    </div>
  </section>
}