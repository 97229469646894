import clsx from 'clsx';
import { useState } from 'react';
import styles from './icons.module.scss';
import { useFundraiserInvitesClient } from '../../data/fundraiser-invites-client';

export const AddParticipantLinkIcon = ({
  fundraiserId,
}: {
  fundraiserId: string;
}) => {
  const fundraiserInvitesClient = useFundraiserInvitesClient();
  const [ open, setOpen ] = useState(false);

  const onClick = async () => {
    // First, create a sharable link.
    try {
      const invite = await fundraiserInvitesClient.create({
        fundraiserId,
      });
      const shareUrl = new URL('/fundraisers/' + fundraiserId, window.origin);
      shareUrl.searchParams.set('invite', invite.id);
      const share = {
        text: `Please join our fundraiser as a participant. ${shareUrl}`,
      };
      if (navigator.canShare && navigator.canShare(share)) {
        navigator.share(share);
      }
      else if (navigator.clipboard) {
        navigator.clipboard.writeText(shareUrl.toString());
        alert('Copied to Clipboard');
      }
      else alert('Copy this link to share: ' + share.text);
    }
    catch (err) {
      alert(err);
    }
  }

  return <div onClick={onClick}>
    <i onClick={() => setOpen(true)} className={clsx('material-icons', styles.raised, styles.round, styles.clickable)}>add_link</i>
  </div>
}
