import clsx from 'clsx';
import styles from './lists.module.scss';
import { Contact } from '../../data/contact-service';
import { useMapping } from '../../hooks/use-mapping';

const ContactListItem = ({
  contact,
}: {
  contact: Contact;
}) => {

  return <li className={clsx(styles.listItem, styles.clickable)}>
    <div className={styles.contactRow}>
      <span>Name: {contact.fullName}</span>
      <span>Phone: {contact.phoneNumber}</span>
      <span>Email: {contact.email}</span>
    </div>
  </li>
}

export const ContactList = ({
  contacts,
}: {
  contacts: Contact[];
}) => {
  const items = contacts.map(contact => {
    return <ContactListItem contact={contact} />
  });

  return <ul className={styles.list}>
    {items}
  </ul>
}