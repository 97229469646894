import clsx from 'clsx';
import styles from './lists.module.scss';
import { Profile } from '../../data/profile-service';
import { Link } from 'react-router-dom';
import { Icon } from '../icons/icon';

export type Option = {
  icon: string;
  label: string;
  link: string;
}

const OptionListItem = ({
  option,
}: {
  option: Option;
}) => {
  return <Link to={option.link}>
    <div className={clsx(styles.option, styles.clickable)}>
      <Icon icon={option.icon} />
      <span>{option.label}</span>
    </div>
  </Link>
}

export const OptionList = ({
  options,
}: {
  options: Option[];
}) => {
  const items = options.map(option=> {
    return <OptionListItem option={option} />
  });

  return <div className={styles.list}>
    {items}
  </div>
}