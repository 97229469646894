import { useMapping } from "../../hooks/use-mapping";

export const PriceLabel = ({
  price,
}: {
  price?: number | null;
}) => {
  const label = useMapping(() => {
    if (!price) return '$0';
    return '$' + (Math.floor(price * 100) / 100 / 100);
  }, [ price ]);
  return <span>{label}</span>
}