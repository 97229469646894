import { Participant } from "../../data/participant-service";
import { useMapping } from "../../hooks/use-mapping";
import { AddContactForm } from "../forms/add-contact-form";
import { Modal } from "./modal"

export const AddContactModal = ({
  open,
  onClose,
  participantId,
  fundraiserId,
}: {
  open: boolean;
  onClose: () => void;
  participantId?: string;
  fundraiserId: string;
}) => {
  const view = useMapping(() => {
    if (!open) return <></>
    return <AddContactForm participantId={participantId} fundraiserId={fundraiserId} onSubmit={onClose} onCancel={onClose} />
  }, [ open ]);

  return <Modal enabled={open} setEnabled={() => onClose()} onClose={onClose}>
    {view}
  </Modal>
}