import { Link } from "react-router-dom";
import { Account } from "../../data/account-service"
import { useMapping } from "../../hooks/use-mapping";
import styles from './lists.module.scss';
import clsx from "clsx";

export const AccountList = ({
  accounts,
}: {
  accounts: Account[];  
}) => {
  const items = useMapping(() => {
    return accounts.map(account => {
      return <Link to={`/admin/accounts/${account.id}`}>
        <li className={clsx(styles.listItem, styles.clickable)}>
          <span>{account.id}</span>
          <span>Email: {account.email}</span>
        </li>
      </Link>
    });
  }, [ accounts ]);

  return <ul className={styles.list}>
    {items}
  </ul>
}