export type Action<A> = (value: A) => void;

export interface Delegate<A> {
  listen: (action: Action<A>) => Action<void>;
}

export class EventDelegate<A> implements Delegate<A> {
  private actions: Action<A>[] = [];

  trigger = (value: A) => {
    for (const action of this.actions) {
      action(value);
    }
  }

  listen = (action: Action<A>) => {
    this.actions.push(action);
    return () => {
      this.actions = this.actions.filter(x => x !== action);
    }
  }
}