import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { EventDelegate } from "../delegate";
import { Async, useAsync } from "../hooks/use-async";
import { defaultHttpClient } from "../hooks/use-http";
import { useAuth } from "../hooks/use-auth";
import { useDonationsClient } from "./donation-service";

export enum FundraiserStatus {
  Pending = 'pending',
  Open = 'open',
  Closed = 'closed',
}

export interface Fundraiser {
  id: string;
  accountId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  slug: string;
  title: string;
  description: string;
  isPublic: boolean;
  status: FundraiserStatus;
  donationsGoal: number; 
  participantGoal: number;
  donationsTotal: number;
  pendingDonations: number | null;
  bannerSrc: string | null;
  startsAt: string | null;
  endsAt: string | null;
}

export type FundraiserInput = {
  accountId: string;
  title: string;
}

export type FundraiserPermission = {
  subject: string;
  access: string;
}

export enum FundraiserSortBy {
  createdAt = 'createdAt',
  createdAtAsc = 'createdAt:asc',
}

export type FundraiserPatch = {
  isPublic?: boolean;
  status?: FundraiserStatus;
  description?: string;
  donationsGoal?: number;
  participantGoal?: number;
  startsAt?: string;
  endsAt?: string;
}

export type FundraiserQueryParams = {
  limit?: number;
  total?: boolean;
  accountId?: string;
  createdBefore?: string;
  createdAfter?: string;
  sortBy?: FundraiserSortBy;
  participantUserId?: string;
  isPublic?: boolean;
}

export type FundraiserPermissionQueryParams = {
  fundraiserId?: string;
  accountId?: string;
  limit?: number;
}

export type FundraiserPage = {
  rows: Fundraiser[];
}

export type FundraiserPermissionPage = {
  rows: FundraiserPermission[];
}

class FundraisersClient {
  onCreated = new EventDelegate<Fundraiser>();
  onUpdated = new EventDelegate<Fundraiser>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: FundraiserInput): Promise<Fundraiser> => {
    const resp = await this.client.post('/api/v1/fundraisers', input);
    this.onCreated.trigger(resp.data);

    return resp.data;
  }

  setBanner = async (fundraiserId: string, file: File): Promise<Fundraiser> => {
    const form = new FormData();

    form.append('banner', file);

    const resp = await this.client({
      method: 'POST',
      url: `/api/v1/fundraisers/${fundraiserId}/banner`,
      data: form,
    });
    this.onUpdated.trigger(resp.data);
    return resp.data;
  }

  update = async (fundraiserId: string, patch: FundraiserPatch): Promise<Fundraiser> => {
    const resp = await this.client.patch(`/api/v1/fundraisers/${fundraiserId}`, patch);
    this.onUpdated.trigger(resp.data);
    return resp.data;
  }

  find = async (query: FundraiserQueryParams={}): Promise<FundraiserPage> => {
    const resp = await this.client.get('/api/v1/fundraisers', {
      params: query,
    });
    return resp.data;
  }

  findPermissions = async (queryParams: FundraiserPermissionQueryParams): Promise<FundraiserPermissionPage> => {
    const resp = await this.client.get('/api/v1/fundraiser-permissions', {
      params: queryParams,
    });
    return resp.data;
  }

  get = async (fundraiserId: string): Promise<Fundraiser> => {
    const resp = await this.client.get(`/api/v1/fundraisers/${fundraiserId}`);
    return resp.data;
  }
}

const fundraisersClient = new FundraisersClient(
  defaultHttpClient,
);

export const useFundraisersClient = () => {
  return fundraisersClient;
}

export const useFundraisers = (query: FundraiserQueryParams={}) => {
  const client = useFundraisersClient();

  const [ rows, setRows ] = useState<Fundraiser[]>([]);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState<Error | null>(null);

  const fetch = async (query: FundraiserQueryParams) => {
    setLoading(true);
    try {
      const page = await client.find({
        ...query,
      });
      setRows(page.rows);
    }
    catch (err) {
      setError(err as Error);
      setRows([]);
    }
    finally {
      setLoading(false);
    }
  }

  const next = async () => {
    const lastRow = rows.slice(-1)[0];
    await fetch({
      ...query,
      createdBefore: lastRow?.createdAt || undefined,
    });
  }

  const previous = async () => {
    const firstRow = rows[0];
    await fetch({
      ...query,
      createdAfter: firstRow?.createdAt || undefined,
    });
  }

  useEffect(() => {
    fetch(query);
    return client.onCreated.listen(() => fetch(query));
  }, [ JSON.stringify(query) ]);

  return {
    loading,
    rows,
    next,
    previous,
    error,
  }
}

export const useFundraiser = (fundraiserId: string): Async<Fundraiser> => {
  const client = useFundraisersClient();
  const donationsClient = useDonationsClient();

  const task = useAsync(async () => {
    return await client.get(fundraiserId);
  }, [ fundraiserId ]);

  useEffect(() => {
    return client.onUpdated.listen(x => {
      if (x.id === fundraiserId) task.setValue(x);
    });
  }, [ ]);
  useEffect(() => donationsClient.onCreate.listen(() => task.trigger()), []);

  return task;
}

export const useFundraiserPermissions = (query: FundraiserPermissionQueryParams) => {
  const client = useFundraisersClient();
  const auth = useAuth();

  return useAsync(async () => {
    if (!auth.value) return { rows: [] };
    
    return await client.findPermissions(query);
  }, [ auth.value, client ]);
}