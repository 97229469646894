import { useEffect, useState } from 'react';
import styles from './sections.module.scss';
import clsx from 'clsx';
import { SimpleButton } from '../buttons/simple-button';
import { Link, useLocation } from 'react-router-dom';
import useAuth0 from '../../hooks/use-auth0';
import { useAuth } from '../../hooks/use-auth';
import { useMapping } from '../../hooks/use-mapping';
import { usePreferences } from '../../hooks/use-preferences';
import { ProfilePortrait, ProfilePortraitSize } from '../images/profile-portrait';
import { Icon } from '../icons/icon';

const BasicNav = () => {
  const auth = useAuth();
  const location = useLocation();

  return useMapping(() => {
    const buttons: JSX.Element[] = [];

    if (!location.pathname.startsWith('/fundraisers')) {
      buttons.push(<SimpleButton small onClick={() => auth.login()}>Sign In</SimpleButton>);
    }

    return <div className={styles.navSection}>
      {buttons}
    </div>
  }, [ ])
}

const AdminNav = () => {
  const [ menuHover, setMenuHover ] = useState(false);
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    setMenuHover(false);
  }, [ location ]);

  return useMapping(() => {
    return <div className={styles.navSection}>
      <Link to='/'>
        <SimpleButton small>Home</SimpleButton>
      </Link>
      <Link to='/search'>
        <Icon icon='search' />
      </Link>
      <SimpleButton small onClick={() => auth.login()}>Sign In</SimpleButton>
    </div>
  }, [ ])
}

const Portrait = () => {
  const [ preferences ] = usePreferences();

  return useMapping(() => {
    return <ProfilePortrait profile={preferences.value?.profile || undefined} size={ProfilePortraitSize.Small} />
  }, [ preferences.value?.profile ]);
}

const AuthorizedNavMenu = () => {
  const auth = useAuth();
  const [ menuHover, setMenuHover ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMenuHover(false);
  }, [ location ]);

  return <div className={styles.navSection}>
    <Link to='/'>
      <SimpleButton small>Home</SimpleButton>
    </Link>
    <div onMouseLeave={() => setMenuHover(false)}>
      <div className={styles.navbarMenu} onMouseOver={() => setMenuHover(true)} onClick={() => setMenuHover(!menuHover)}>
        <Portrait />
        <Icon icon='expand_more' />
      </div>
      <div className={clsx(styles.navDropdown, menuHover ? null : styles.hidden)}>
        <div className={styles.dropdownPanel}>
          <Link to='/settings'>
            <SimpleButton flat>Settings</SimpleButton>
          </Link>
          <SimpleButton flat onClick={auth.logout}>Logout</SimpleButton>
        </div>
      </div>
    </div>
  </div>
}

export const Navbar = () => {
  const auth = useAuth();
  const location = useLocation();
  const isManage = useMapping(() => {
    return location.pathname.startsWith('/manage') || location.pathname.startsWith('/admin');
  }, [ location.pathname ]);

  const menu = useMapping(() => {
    if (auth.value) return <AuthorizedNavMenu />
    if (isManage) return <AdminNav />

    return <BasicNav />
  }, [ auth.value, isManage ]);

  return <div className={styles.navbar}>
    <div className={styles.logo}>
      <img src="https://qwikraise.com/wp-content/uploads/2023/05/QR_Logo-White.svg" />
    </div>
    {menu}
  </div>
}