import { useState } from "react";
import { useParams } from "react-router-dom";
import { Participant, useParticipant } from "../../data/participant-service";
import { useMapping } from "../../hooks/use-mapping";
import { ParticipantContactsSection } from "../sections/participant-contacts-section";
import { ParticipantSection } from "../sections/participant-section";
import { TabSection } from "../sections/tab-section";
import styles from './pages.module.scss';

enum Tab {
  Participant = 'Participant',
  Contacts = 'Contacts',
}

const ParticipantView = ({
  participant,
}: {
  participant: Participant;
}) => {
  return <ParticipantSection participant={participant} /> 
}

export const ParticipantPage = () => {
  const {
    participantId,
  } = useParams() as Record<string, string>;
  const participant = useParticipant(participantId);
  const [ view, setView ] = useState(<></>);

  const onTab = (tab: Tab) => {
    if (!participant.value) return;

    setView((() => {
      switch (tab) {
        case Tab.Participant:
          return <ParticipantView participant={participant.value} />

        case Tab.Contacts:
          return <ParticipantContactsSection participantId={participantId} />
      }
    })());
  }
  return useMapping(() => {
    if (!participant.value) return <></>

    return <div className={styles.pageWrapper}>
      <div className={styles.page}>
        <TabSection
          defaultTab={Tab.Participant}
          tabs={Object.values(Tab)}
          onSelected={onTab}
        />
        {view}
      </div>
    </div>
  }, [ participant.value, view ]);
}