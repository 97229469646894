import { useState } from "react";
import { FundraiserQueryParams, useFundraisers } from "../../data/fundraiser-service"
import { SimpleButton } from "../buttons/simple-button";
import { FundraiserList } from "../lists/fundraiser-list";
import { AddFundraiserModal } from "../modals/add-fundraiser-modal";
import styles from './sections.module.scss';
import { Auth, Scope, useAuth } from "../../hooks/use-auth";
import { useMapping } from "../../hooks/use-mapping";
import clsx from 'clsx';
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddFundraiserIcon } from "../icons/add-fundraiser-icon";
import { FeatureFlag, useAccountFeatures, useAccountsClient } from "../../data/account-service";
import { useAsync } from "../../hooks/use-async";

export const EventsListSection = ({
  query={},
  showPaging=false,
  auth,
}: {
  query?: FundraiserQueryParams;
  showPaging?: boolean;
  auth?: Auth;
}) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();
  const createdBefore = useMapping(() => {
    const value = searchParams.get('createdBefore');
    if (!value) return undefined;
    return value;
  }, [ searchParams ]);
  const accountsClient = useAccountsClient();
  const accountFeatures = useAsync(async () => {
    if (!auth) return null;
    return await accountsClient.getFeatures(auth.accountId);
  }, [ auth?.accountId ]);

  const limit = 8;
  const fundraisers = useFundraisers({
    ...query,
    limit,
    createdBefore,
  });
  const [ createOpen, setCreateOpen ] = useState(false);

  const create = useMapping(() => {
    if (!accountFeatures.value?.find(x => x.enabled && x.featureFlag === FeatureFlag.WriteFundraisers)) return <></>

    return <>
      <AddFundraiserIcon />
    </>
  }, [ accountFeatures.value ]);

  const onPrevious = () => fundraisers.previous();
  const onNext = () => fundraisers.next();

  const paging = useMapping(() => {
    if (!showPaging) return <></>

    return <div className={clsx(styles.flex, styles.center, styles.gap2)}>
      <SimpleButton disabled={createdBefore === undefined} small onClick={onPrevious}>{"< Previous"}</SimpleButton>
      <SimpleButton disabled={(fundraisers.rows.length || 0) < limit} small onClick={onNext}>{"Next >"}</SimpleButton>
    </div>
  }, [ showPaging, fundraisers.rows ]);

  return <section className={styles.section}>
    <div className={clsx(styles.flex, styles.centerV)}>
      <h4 className={styles.sectionTitle}>Fundraisers</h4>
      {create}
    </div>
    {paging}
    <div className={clsx(styles.flex, styles.centerV)}>
      <div className={styles.margin1}>
        {fundraisers.error?.message}
      </div>
    </div>
    <FundraiserList adminLinks fundraisers={fundraisers.rows || []} />
    <AddFundraiserModal open={createOpen} onClose={() => setCreateOpen(false)} />
  </section>
}