import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Fundraiser, useFundraisersClient } from '../../data/fundraiser-service';
import { SimpleButton } from '../buttons/simple-button';
import styles from './forms.module.scss';
import { ProfilePicker } from '../inputs/profile-picker';
import { Profile, ProfilePage, useProfiles, useProfilesClient } from '../../data/profile-service';
import { useMapping } from '../../hooks/use-mapping';
import { useAsync } from '../../hooks/use-async';
import { ProfileList } from '../lists/profile-list';
import { useParticipantsClient } from '../../data/participant-service';

const ProfileView = ({
  profile,
}: {
  profile: Profile;
}) => {
  return <div className={styles.profilePreview}>
    <img className={styles.profilePortrait} src={`/api/v1/profiles/${profile.id}/portrait?cb=${new Date().getTime()}`} alt='This user has no profile picture.' />
    <h3>{profile.name}</h3>
  </div>
}

const PickProfileView = ({
  setProfile,
}: {
  setProfile: (x: Profile) => void;  
}) => {
  const [ error, setError ] = useState<Error | null>(null);
  const [ search, setSearch ] = useState('');

  const profilesClient = useProfilesClient();
  const options = useAsync(async () => {
    return await profilesClient.find({
      name: search,
      limit: 16,
    });
  }, [ search ], {
    defer: true,
    default: null,
    debounce: true,
    delay: 250,
  });

  useEffect(() => {
    if (!search) return;
    options.trigger();
  }, [ search ]);

  const bodyView = useMapping(() => {
    if (options.loading) return <></>
    if (options.error) return <></>
    if (options.value === null) {
      return <> </>
    }

    if (options.value.rows.length === 0) return <div className={clsx(styles.flex, styles.center)}>
      <span>Sorry! No profiles were found.</span>
    </div>

    return <>
      <ProfileList profiles={options.value.rows} onClickProfile={setProfile} />
    </>
  }, [ options.value ]);

  return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
    <h1>Add a Participant</h1>

    <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
      <label>Profile Name</label>
      <input onChange={e => setSearch(e.target.value)} />
    </div>
    <div className={styles.optionsBox}>
      {bodyView}
    </div>
  </div>
}

export const AddParticipantForm = ({
  fundraiserId,
  onCancel,
  onSubmit,
}: {
  fundraiserId: string;
  onCancel: () => void;
  onSubmit: () => void;
}) => {
  const participantsClient = useParticipantsClient()

  const [ error, setError ] = useState<Error | null>(null);
  const [ profile, setProfile ] = useState<Profile | null>(null);

  const submit = async (e: any) => {
    setError(null);
    const targets = e.target.elements;

    try {
      if (profile === null) {
        throw new Error('Profile not selected');
      }

      await participantsClient.create({
        fundraiserId,
        profileId: profile.id,
      });

      onSubmit();
    }
    catch (err) {
      setError(err as Error);
    }

    return true;
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    onCancel();
    return false;
  }

  return useMapping(() => {
    if (!profile) {
      return <PickProfileView setProfile={x => { setProfile(x); setError(null) }} />
    }
    if (error) {
      return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
        <h1>Add a Participant</h1>
        <h4>Sorry, an error occurred.</h4>
        <span>
          {error.message || ''}
        </span>
        <SimpleButton>Submit</SimpleButton>
        <SimpleButton type="button" onClick={() => { onCancel(); return false; }}>Cancel</SimpleButton>
      </div>
    }

    return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
      <h1>Add a Participant</h1>
      <ProfileView profile={profile} />
      <form className={styles.fullWidth} action="javascript:void(0);" onSubmit={submit}>
        <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
          <SimpleButton type='submit'>Submit</SimpleButton>
          <SimpleButton type="button" onClick={handleCancel}>Cancel</SimpleButton>
        </div>
      </form>
    </div>
  }, [ error, profile ]);
}