import clsx from "clsx";
import { useState } from "react";
import { Auth, Scope, useAuth } from "../../hooks/use-auth";
import { useMapping } from "../../hooks/use-mapping";
import { EditProfileSection } from "../sections/edit-profile-section";
import { EventsListSection } from "../sections/events-list-section";
import { TabSection } from "../sections/tab-section";
import styles from './pages.module.scss';
import { CreateProfilePage, StandardHomePage } from "./standard-home-page";
import { useProfiles } from "../../data/profile-service";
import { usePreferences } from "../../hooks/use-preferences";
import { useAccountFeatures, useAccountsClient } from "../../data/account-service";
import { useAsync } from "../../hooks/use-async";

enum Tab {
  Summary = 'Summary',
  Fundraisers = 'Fundraisers',
}

const SummaryTab = () => {
  const auth = useAuth();

  return useMapping(() => {
    return <>
      <EditProfileSection />
    </>
  }, [ JSON.stringify(auth.value) ]);
}

const FundraisersTab = ({
  auth,
}: {
  auth: Auth;
}) => {
  return <EventsListSection auth={auth} showPaging={true} query={{ accountId: auth.accountId }} />
}

export const AdminHomePage = ({
  auth,
}: {
  auth: Auth;
}) => {
  const [ view, setView ] = useState(<></>);

  const onTab = (tab: Tab) => {
    const newView = (() => {
      switch (tab) {
        case Tab.Summary:
          return <SummaryTab />

        case Tab.Fundraisers:
          return <FundraisersTab auth={auth} />
      }
    })();

    setView(newView);
  }

  return <div className={styles.pageWrapper}>
    <div className={clsx(styles.page)}>
      <TabSection
        defaultTab={Tab.Summary}
        tabs={Object.values(Tab)}
        onSelected={onTab}
      />
      {view}
    </div>
  </div>
}

export const HomePage = () => {
  const auth = useAuth();
  const [ preferences ] = usePreferences();
  const accountsClient = useAccountsClient();
  const accountFeatures = useAsync(async () => {
    if (!auth.value) return null;

    return await accountsClient.getFeatures(auth.value.accountId);
  }, [ auth.value?.accountId ]);

  return useMapping(() => {
    if (auth.value === null) return <></>
    if (!preferences.value) return <></>
    if (!accountFeatures.value) return <></>
    if (!preferences.value.profile) return <CreateProfilePage />
  
    if (accountFeatures.value.find(x => x.enabled && x.featureFlag === 'write:fundraisers')) {
      return <AdminHomePage auth={auth.value} />
    }

    return <StandardHomePage />
  }, [ JSON.stringify(auth.value), preferences.value, accountFeatures.value ]);
}