import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useMapping } from '../../hooks/use-mapping';
import styles from './inputs.module.scss';
import { Fundraiser, useFundraisersClient } from '../../data/fundraiser-service';

export const BannerInput = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const fundraisersClient = useFundraisersClient();
  const fileRef = useRef<HTMLInputElement>(null);
  const [ file, setFile ] = useState<File | null>(null);

  const onFiles = (e: any) => {
    if (!e.target) return;

    setFile(e.target.files[0]);
  }

  const view = useMapping(() => {
    if (file) {
      return <img className={styles.bannerPreview} src={URL.createObjectURL(file)} />
    }

    if (!fundraiser.bannerSrc) {
      return <label htmlFor='file'>Choose file to upload banner.</label>
    }

    return <img className={styles.bannerPreview} src={`/api/v1/fundraisers/${fundraiser.id}/banner`} alt='' />
  }, [ file, fundraiser.bannerSrc ]);

  useEffect(() => {
    if (!file) return;

    fundraisersClient.setBanner(fundraiser.id, file);
  }, [ file ]);

  const onClick = () => {
    if (!fileRef.current) return;

    fileRef.current.click();
  }

  return <div className={styles.bannerContainer} onClick={onClick}>
    <input className={styles.hidden} ref={fileRef} type='file' name='file' accept="image/*" title=" " onChange={onFiles} />
    {view}
  </div>
}