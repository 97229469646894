import styles from './headings.module.scss';

export const Subheading = ({
  label,
}: {
  label: string;
}) => {
  return <div className={styles.subheading}>
    <h3>{label}</h3>
  </div>
}