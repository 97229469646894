import { AxiosInstance } from "axios";
import { defaultHttpClient } from "../hooks/use-http";
import { useMapping } from "../hooks/use-mapping";
import { useAsync } from "../hooks/use-async";
import { EventDelegate } from "../delegate";

export type DonationInput = {
  fundraiserId: string;
  participantId?: string;
  contactId?: string;
  donorName?: string;
  amount: number;
  message?: string;
  paymentMethod: {
    card: {
      number: string;
      expirationDate: string;
      cvc: string;
    }
  };
  billingAddress: {
    firstName: string;
    lastName: string;
    companyName?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    phoneNumber: string;
    email: string;
  };
}

export type DonationIntent = {
  secret: string;
}

export enum DonationStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface Donation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  amount: number;
  currency: string;
  status: DonationStatus;
  errors: any[];
  fundraiserId: string;
  participantId: string | null;
  contactId: string | null;
  donorProfileId: string | null;
  donorName: string | null;
  message: string | null;
}

export type DonationCreateResult = {
  donation: Donation;
  secret: string;
}

export type DonationPage = {
  rows: Donation[];
}

export type DonationQueryParams = {
  limit?: number;
  fundraiserId?: string;
  participantId?: string;
  profileId?: string;
  statusIn?: DonationStatus[];
}

export class DonationsClient {
  onCreate = new EventDelegate<Donation>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: DonationInput): Promise<DonationCreateResult> => {
    const resp = await this.client.post('/api/v1/donations', input);
    this.onCreate.trigger(resp.data.donation);
    return resp.data;
  }

  find = async (params: DonationQueryParams): Promise<DonationPage> => {
    const resp = await this.client.get('/api/v1/donations', {
      params,
    });
    return resp.data;
  }
}

const donationsClient = new DonationsClient(defaultHttpClient);

export const useDonationsClient = () => {
  return useMapping(() => donationsClient, []);
}

export const useDonations = (params: DonationQueryParams) => {
  const donationsClient = useDonationsClient();

  return useAsync(async () => {
    return await donationsClient.find(params);
  }, [ JSON.stringify(params) ])
}