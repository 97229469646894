import { useEffect, useState } from 'react';
import { TabButton } from '../buttons/tab-button';
import styles from './sections.module.scss';
import clsx from 'clsx';

export enum AdminTab {
  Events = 'events',
  Accounts = 'accounts',
}

export const AdminTabSection = ({
  onSelection,
}: {
  onSelection: (x: AdminTab) => void;
}) => {
  const [ tab, setTab ] = useState<AdminTab>(AdminTab.Events);

  useEffect(() => {
    onSelection(tab);
  }, [ tab ]);

  return <section className={clsx(styles.section, styles.tabSection)}>
    <TabButton active={tab === AdminTab.Events} label='Events' onClick={() => setTab(AdminTab.Events)} />
    <TabButton active={tab === AdminTab.Accounts} label='Accounts' onClick={() => setTab(AdminTab.Accounts)} />
  </section>
}