import clsx from 'clsx';
import { useState } from 'react';
import { Fundraiser, useFundraisersClient } from '../../data/fundraiser-service';
import { SimpleButton } from '../buttons/simple-button';
import styles from './forms.module.scss';
import { useAuth, useAuthValue } from '../../hooks/use-auth';

export const AddFundraiserForm = ({
  onCancel,
  onCreated,
}: {
  onCancel: () => void;
  onCreated: (x: Fundraiser) => void;
}) => {
  const fundraisersClient = useFundraisersClient();
  const auth = useAuthValue();
  const [ error, setError ] = useState<Error | null>(null);

  const onSubmit = async (e: any) => {
    setError(null);
    const targets = e.target.elements;

    try {
      const fundraiser = await fundraisersClient.create({
        title: targets.title.value,
        accountId: auth.accountId,
      });

      // Clear the inputs.
      for (const target of targets) {
        target.value = '';
      }
      onCreated(fundraiser);
    }
    catch (err) {
      setError(err as Error);
    }

    return true;
  }

  const handleCancel = (e: any) => {
    e.preventDefault();
    onCancel();
    return false;
  }

  return <div className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
    <h1>New Fundraiser</h1>
    <span>
      {error?.message || ''}
    </span>
    <form className={styles.fullWidth} action="javascript:void(0);" onSubmit={onSubmit}>
      <label>Title</label>
      <input autoFocus={true} tabIndex={0} name="title" />

      <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
        <SimpleButton type="submit">Submit</SimpleButton>
        <SimpleButton type="button" onClick={handleCancel}>Cancel</SimpleButton>
      </div>
    </form>
  </div>
}