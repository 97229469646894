import clsx from "clsx";
import { Fundraiser, useFundraisersClient } from "../../data/fundraiser-service";
import { useAsync } from "../../hooks/use-async";
import { Icon } from "../icons/icon";
import { TextArea } from "../inputs/textarea";
import styles from './forms.module.scss';
import { useState } from "react";
import { useMapping } from "../../hooks/use-mapping";
import { ErrorLabel } from "../labels/error-label";

export const SetFundraiserGoalForm = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const fundraisersClient = useFundraisersClient();
  const [ goal, setGoal ] = useState(fundraiser.donationsGoal / 100);

  const update = useAsync(async () => {
    if (update.loading) return null;

    await fundraisersClient.update(fundraiser.id, {
      donationsGoal: goal * 100,
    });
  }, [ fundraiser.id, goal ], {
    defer: true,
    default: null,
  });

  const icons = useMapping(() => {
    if (goal * 100 === fundraiser.donationsGoal) return null;

    return <>
      <button disabled={update.loading} type='button' className={styles.iconButton} onClick={() => setGoal(fundraiser.donationsGoal)} >
        <Icon icon='undo' />
      </button>
      <button disabled={update.loading} type='submit' className={styles.iconButton} onClick={() => update.trigger()} >
        <Icon icon='check' />
      </button>
    </>
  }, [ fundraiser.donationsGoal, goal, update.loading ]);

  return <form className={clsx(styles.flatForm, styles.gap1)} action="javascript:void(0);" onSubmit={() => update.trigger()}>
    <ErrorLabel error={update.error} />
    <label>Goal Amount</label>
    <input type='number' min={1} value={goal} placeholder={goal.toString()} onChange={e => setGoal(Number.parseInt(e.currentTarget.value)) } />
    <div className={styles.flex}>
      {icons}
    </div>
  </form>
}