import clsx from 'clsx';
import { Fundraiser } from '../../data/fundraiser-service';
import styles from './sections.module.scss';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAsync } from '../../hooks/use-async';
import useHttp from '../../hooks/use-http';

export const FundraiserAnalyticsSection = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const now = new Date(1702318010323);
  const http = useHttp();
  const data = useAsync(async () => {
    const resp = await http.client.get(`/api/v1/fundraisers/${fundraiser.id}/histogram`);
    return resp.data.data.map((x: any) => {
      return {
        createdAt: new Date(x.timestamp),
        amount: x.amount / 100,
      }
    });
  }, [ fundraiser.id ]);
  // const data = [
  //   {
  //     createdAt: new Date(now.setDate(now.getDate() - 4)).toISOString(),
  //     amount: 100,
  //   },
  //   {
  //     createdAt: new Date(now.setDate(now.getDate() - 3)).toISOString(),
  //     amount: 1000,
  //   },
  //   {
  //     createdAt: new Date(now.setDate(now.getDate() - 2)).toISOString(),
  //     amount: 200,
  //   },
  //   {
  //     createdAt: new Date(now.setDate(now.getDate() - 1)).toISOString(),
  //     amount: 300,
  //   },
  // ];

  return <section className={clsx(styles.section)}>
    <div className={styles.center}>
    <div style={{ width: '80%', height: '20rem', marginTop: '4rem' }}>
      <ResponsiveContainer width="100%" height="100%">
      <LineChart width={400} height={400} data={data.value || []} margin={{ top: 5, right: 20, left: 10, bottom: 20 }}>
        <XAxis label={{
                    key: 'date',
                    value: 'Date',
                    position: 'bottom',
        }}
          dataKey="createdAt"
          tickFormatter={x => new Date(x).toLocaleDateString()}
        />
        <YAxis label={{
          value: 'Amount $',
          angle:  -90,
          position: 'left',
          offset: 0,
        }} />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <Line type="monotone" dataKey="amount" stroke="#ff7300" yAxisId={0} />
      </LineChart>
      </ResponsiveContainer>
    </div>
    </div>
  </section>
}
