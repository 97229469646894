import clsx from 'clsx';
import styles from './lists.module.scss';
import { Donation } from '../../data/donation-service';
import { useMapping } from '../../hooks/use-mapping';
import { ProfilePortrait, ProfilePortraitSize } from '../images/profile-portrait';
import { useProfile } from '../../data/profile-service';
import { PriceLabel } from '../labels/price-label';

const DonorProfilePortrait = ({
  profileId,
}: {
  profileId: string;
}) => {
  const profile = useProfile(profileId);
  return <>
    <ProfilePortrait size={ProfilePortraitSize.Small} profile={profile.value} />
    <h5>{profile.value?.name}</h5>
  </>
}

const ProfileDonorListItem = ({
  donation,
  profileId,
}: {
  donation: Donation;
  profileId: string;
}) => {
  const profile = useProfile(profileId);

  return useMapping(() => {
    if (!profile.value) return <></>

    return <>
      <ProfilePortrait size={ProfilePortraitSize.Small} profile={profile.value} />
      <div className={clsx(styles.donationItemContent, styles.gap1)}>
        <div className={clsx(styles.flexRow, styles.spaceBetween)}>
          <span className={styles.title}>{profile.value.name}</span>
          <PriceLabel price={donation.amount} />
        </div>
        <div className={styles.flexRow}>
          <span className={styles.donationMessage}>{donation.message}</span>
        </div>
      </div>
    </>
  }, [ profile.value ]);
}

const DonorListItem = ({
  donation,
}: {
  donation: Donation;
}) => {
  return <>
    <ProfilePortrait size={ProfilePortraitSize.Small} />
    <div className={clsx(styles.donationItemContent, styles.gap1)}>
      <div className={clsx(styles.flexRow, styles.spaceBetween)}>
        <span className={styles.title}>{donation.donorName || 'Anonymous'}</span>
        <PriceLabel price={donation.amount} />
      </div>
      <div className={styles.flexRow}>
        <span className={styles.donationMessage}>{donation.message}</span>
      </div>
    </div>
  </>
}

const DonationListItem = ({
  donation,
}: {
  donation: Donation;
}) => {
  const view = useMapping(() => {
    if (donation.donorProfileId) return <ProfileDonorListItem donation={donation} profileId={donation.donorProfileId} />
    else return <DonorListItem donation={donation} />
  }, [ donation.donorProfileId ]);

  // const view = useMapping(() => {
  //   if (donation.donorProfileId) return <DonorProfileInfo profileId={donation.donorProfileId} />
  //   return <>
  //     <ProfilePortrait size={ProfilePortraitSize.Small} />
  //     <h5>{donation.donorName || 'Anonymous'}</h5>
  //   </>
  // }, [ donation ]);

  return <li className={clsx(styles.donationListItem, styles.padding1, styles.gap1)}>
    {view}
  </li>

  // return <li className={clsx(styles.listItem, styles.padLeft)}>
  //   <div className={styles.participantRow}>
  //     <div className={clsx(styles.flexRow, styles.centerAlign, styles.spaceBetween)}>
  //       {donorInfo}
  //     </div>
  //     <div className={clsx(styles.flex, styles.center)}>
  //       <div>Donated <PriceLabel price={donation.amount} /></div>
  //       <div className={styles.donationMessage}>{donation.message}</div>
  //     </div>
  //   </div>
  // </li>
}

export const DonationList = ({
  donations,
}: {
  donations: Donation[];
}) => {
  const items = useMapping(() => {
    return donations.map(donation => {
      return <DonationListItem donation={donation} />
    });
  }, [ donations ]);

  return <ul className={styles.list}>
    {items}
  </ul>
}