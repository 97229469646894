import { AxiosInstance } from "axios";
import { defaultHttpClient } from "../hooks/use-http";
import { useMapping } from "../hooks/use-mapping";

export type TrackingEventInput = {
  subject?: string;
  [x: string]: any;
}

export class TrackingClient {
  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: TrackingEventInput): Promise<void> => {
    const resp = await this.client.post('/api/v1/tracking-events', input);
    return resp.data;
  }
}

export const trackingClient = new TrackingClient(defaultHttpClient);

export const useTracking = () => {
  return useMapping(() => trackingClient, []);
}