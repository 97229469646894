import { AxiosInstance } from "axios";
import { EventDelegate } from "../delegate";
import { defaultHttpClient } from "../hooks/use-http";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useEffect } from "react";

export type Coach = {
  id: string;
  fundraiserId: string;
  profileId: string;
}

export type CoachInput = {
  fundraiserId: string;
  profileId: string;
}

export type coacheQueryParams = {
  fundraiserId?: string;
}

export type coachePage = {
  rows: Coach[];
}

export class CoachesClient {
  onCreated = new EventDelegate<Coach>();
  onUpdated = new EventDelegate<Coach>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: CoachInput) => {
    const resp = await this.client.post('/api/v1/coaches', input);
    this.onCreated.trigger(resp.data);
    return resp.data;
  }

  find = async (query?: coacheQueryParams): Promise<coachePage> => {
    const resp = await this.client.get('/api/v1/coaches', {
      params: query,
    });
    return resp.data;
  }

  get = async (participantId: string) => {
    const resp = await this.client.get(`/api/v1/coaches/${participantId}`);
    return resp.data;
  }
}

const coachesClient = new CoachesClient(defaultHttpClient);

export const useCoachesClient = () => {
  return coachesClient;
}

export const useCoaches = <A,>(query?: coacheQueryParams, asyncArgs?: AsyncArgs<A>) => {
  const profilesClient = useCoachesClient();
  const task = useAsync(async () => {
    return await profilesClient.find(query);
  }, [ JSON.stringify(query) ], asyncArgs);

  useEffect(() => {
    return profilesClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}