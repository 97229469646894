import clsx from 'clsx';
import { useContacts, useParticipantContacts } from '../../data/contact-service';
import { Participant, useParticipant, useParticipantsClient } from "../../data/participant-service";
import { Profile, useProfile, useProfilesClient } from "../../data/profile-service";
import { ContactList } from "../lists/contact-list";
import styles from './sections.module.scss';
import { useMapping } from '../../hooks/use-mapping';
import { FC } from 'react';
import { useAsync } from '../../hooks/use-async';
import { AddContactIcon } from '../icons/add-contact-icon';

const Section = ({
  participant,
  profile,
}: {
  participant: Participant;
  profile: Profile;
}) => {
  const contacts = useContacts({
    participantId: participant.id,
  });

  return <div className={styles.section}>
    <div className={clsx(styles.fullWidth, styles.flex, styles.center, styles.columnar, styles.gap0, styles.gapTop)}>
      <div className={clsx(styles.columnar, styles.center)}>
        <h4>Contacts from {profile.name}</h4>
      </div>
      <AddContactIcon participantId={participant.id} fundraiserId={participant.fundraiserId} />
    </div>
    <ContactList contacts={contacts.value?.rows || []} />
  </div>
}

export const ParticipantContactsSection = ({
  participantId,
}: {
  participantId: string;
}) => {
  const profilesClient = useProfilesClient();
  const participantsClient = useParticipantsClient();

  const task = useAsync(async () => {
    const participant = await participantsClient.get(participantId);
    const profile = await profilesClient.get(participant.profileId);
    return {
      participant,
      profile,
    }
  }, [ participantId ]);

  return useMapping(() => {
    if (!task.value) return <></>

    return <Section
      profile={task.value.profile}
      participant={task.value.participant}
    />
  }, [ task.value ]);
}