import clsx from "clsx";
import { Profile } from "../../data/profile-service";
import { useMapping } from "../../hooks/use-mapping";
import styles from './images.module.scss';

const portraitSrc = (profileId: string, src: string) => {
  return `/api/v1/profiles/${profileId}/portrait?${src}`;
}

const placeholderImg = '/images/profile-placeholder.webp';

export enum ProfilePortraitSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export const ProfilePortrait = ({
  profile,
  size,
  file,
}: {
  profile?: Profile;
  size: ProfilePortraitSize;
  file?: File;
}) => {
  const src = useMapping(() => {
    if (file) return URL.createObjectURL(file);

    if (!profile?.portraitSrc) return placeholderImg;
    return portraitSrc(profile.id, profile.portraitSrc);
  }, [ profile?.id, profile?.portraitSrc ])

  return <img className={clsx(styles.portrait, styles[size])} alt="" src={src} />
}