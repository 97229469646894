import { Fundraiser } from "../../data/fundraiser-service";
import { useMapping } from "../../hooks/use-mapping";
import styles from './images.module.scss';

export const FundraiserBanner = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const src = useMapping(() => {
    if (!fundraiser.bannerSrc) return undefined;

    return `/api/v1/fundraisers/${fundraiser.id}/banner`;
  }, [ fundraiser.bannerSrc ]);

  return <img className={styles.banner} src={src} />
}