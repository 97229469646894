import clsx from "clsx";
import { useEffect, useState } from "react";
import { Profile, useProfilesClient } from "../../data/profile-service";
import { useAsync } from "../../hooks/use-async";
import { useMapping } from "../../hooks/use-mapping";
import { Icon } from "../icons/icon";
import { ErrorLabel } from "../labels/error-label";
import styles from './forms.module.scss';
import { usePreferences } from "../../hooks/use-preferences";

export const SetProfileNameForm = ({
  profile,
}: {
  profile: Profile;
}) => {
  const profilesClient = useProfilesClient();
  const [ name, setName ] = useState(profile.name);

  const update = useAsync(async () => {
    if (profile.name === name) return profile;

    return await profilesClient.update(profile.id, {
      name,
    });
  }, [ profile.id, profile.name, name ], {
    defer: true,
    default: null,
  });

  const icons = useMapping(() => {
    if (name === profile.name) return null;

    return <>
      <button disabled={update.loading} type='button' className={styles.iconButton} onClick={() => setName(profile.name)} >
        <Icon icon='undo' />
      </button>
      <button disabled={update.loading} type='submit' className={styles.iconButton} onClick={() => update.trigger()} >
        <Icon icon='check' />
      </button>
    </>
  }, [ profile.name, name ]);

  return <form className={clsx(styles.flatForm, styles.gap1)} action="javascript:void(0);" onSubmit={() => update.trigger()}>
    <ErrorLabel error={update.error} />
    <label>Full Name</label>
    <input type='text' placeholder={name} onChange={e => setName(e.currentTarget.value)} />
    <div className={styles.flex}>
      {icons}
    </div>
  </form>
}