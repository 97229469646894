import clsx from 'clsx';
import { useState } from 'react';
import { AddParticipantModal } from '../modals/add-participant-modal';
import styles from './icons.module.scss';

export const Icon = ({
  icon,
}: {
  icon: string;
}) => {
  return <div className={clsx(styles.icon, styles.round, styles.raised, styles.clickable)}>
    <i className='material-icons'>{icon}</i>
  </div>
}
