import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Profile, useProfilesClient } from '../../data/profile-service';
import { ProfilePortrait, ProfilePortraitSize } from '../images/profile-portrait';
import styles from './inputs.module.scss';

export const ProfilePortraitInput = ({
  profile,
}: {
  profile: Profile;
}) => {
  const profilesClient = useProfilesClient();
  const fileRef = useRef<HTMLInputElement>(null);
  const [ file, setFile ] = useState<File | null>(null);

  const onFiles = (e: any) => {
    if (!e.target) return;

    setFile(e.target.files[0]);
  }

  useEffect(() => {
    if (!file) return;

    profilesClient.setPortrait(profile.id, file);
  }, [ file ]);

  const onClick = () => {
    if (!fileRef.current) return;

    fileRef.current.click();
  }


  return <div className={clsx(styles.portraitContainer)} onClick={onClick}>
    <ProfilePortrait profile={profile} file={file || undefined} size={ProfilePortraitSize.Large} />
    <input className={styles.hidden} ref={fileRef} type='file' name='file' accept="image/*" title=" " onChange={onFiles} />
  </div>
}