import { useMapping } from "../../hooks/use-mapping";
import { AddFundraiserForm } from "../forms/add-fundraiser-form";
import { Modal } from "./modal";

export const AddFundraiserModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const view = useMapping(() => {
    if (!open) return <></>

    return <AddFundraiserForm onCreated={() => onClose()} onCancel={onClose} />
  }, [ open ]);

  return <Modal enabled={open} setEnabled={() => onClose()} onClose={onClose}>
    {view}
  </Modal>
}