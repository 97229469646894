import clsx from 'clsx';
import { useState } from 'react';
import styles from './icons.module.scss';
import { useFundraiserInvitesClient } from '../../data/fundraiser-invites-client';
import { ParticipantInviteModal } from '../modals/participant-invite-link-modal';

export const AddParticipantQRIcon = ({
  fundraiserId,
}: {
  fundraiserId: string;
}) => {
  const fundraiserInvitesClient = useFundraiserInvitesClient();
  const [ open, setOpen ] = useState(false);

  return <div>
    <i onClick={() => setOpen(true)} className={clsx('material-icons', styles.raised, styles.round, styles.clickable)}>qr_code</i>
    <ParticipantInviteModal fundraiserId={fundraiserId} open={open} onClose={() => setOpen(false)} />
  </div>
}