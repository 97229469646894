import { useEffect } from 'react';
import { Account } from '../data/account-service';
import { Profile, useProfilesClient } from '../data/profile-service';
import { Async, useAsync } from './use-async';
import { useAuth } from './use-auth';

export type Preferences = {
  profile: Profile | null;
  account: Account | null;
}

export const usePreferences = (): [ Async<Preferences> ] => {
  const profilesClient = useProfilesClient();
  const auth = useAuth();

  const preferences = useAsync<Preferences>(async () => {
    if (!auth.value || !auth.value.accountId) return {
      profile: null,
      account: null,
    }

    const profiles = await profilesClient.find({
      accountId: auth.value.accountId,
    });
    const profile = profiles.rows[0] || null;

    const preferences: Preferences = {
      profile,
      account: null,
    };
    return preferences;
  }, [ auth.value ]);

  const setPreferences = (value: Preferences) => {
    preferences.setValue(value);
  }

  useEffect(() => {
    if (!auth.value) return;
    if (!preferences.value) return;

    const closeOnUpdated = profilesClient.onUpdated.listen(profile => {
      setPreferences({
        account: preferences.value?.account,
        profile,
      });
    });
    const closeOnCreated = profilesClient.onCreated.listen(profile => {
      setPreferences({
        account: preferences.value?.account,
        profile,
      });
    });

    return () => {
      closeOnUpdated();
      closeOnCreated();
    }
  }, [ auth.value, preferences.value ]);

  return [
    preferences,
  ];
}