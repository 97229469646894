import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import styles from './buttons.module.scss';

export const SimpleButton = ({
  flat=false,
  small,
  disabled=false,
  onClick,
  className,
  ...props
}: {
  flat?: boolean;
  disabled?: boolean;
  small?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return <button onClick={onClick}
    className={clsx(styles.button, flat && styles.flat, small && styles.small, disabled && styles.disabled, className)}
    {...props}
  />
}