import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { useFundraiserInvitesClient } from '../../data/fundraiser-invites-client';
import { useAsync } from '../../hooks/use-async';
import { useMapping } from '../../hooks/use-mapping';
import { Modal } from '../modals/modal';
import styles from './modals.module.scss';
import { useEffect, useRef, useState } from 'react';

const ParticipantInviteModalBody = ({
  url,
}: {
  url: string;
}) => {
  const [ imageData, setImageData ] = useState<string | null>(null);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (!ref.current) return;

    const svg = ref.current;
    if (!svg) return;

    const xml = new XMLSerializer().serializeToString(svg);
    const xmlBuf = btoa(xml);
    const imageData = 'data:image/svg+xml;base64,' + xmlBuf;
    setImageData(imageData);
  }, [ ref.current ]);

  return <div style={{ alignItems: 'center' }} className={clsx(styles.body, styles.flexCol, styles.gap1)}>
    <div>
      <span>Copy this QR Code to become a participant.</span>
    </div>
    <img
      style={{ height: "20rem", maxWidth: "20rem", width: "100%" }}
      src={imageData || undefined} />
    <div style={{ visibility: 'hidden', position: 'absolute' }}>
      <QRCode
        ref={ref as any}
        size={256}
        style={{ height: '20rem', width: "20rem" }}
        value={url}
        viewBox={`0 0 256 256`}
      />
    </div>
  </div>
}

export const ParticipantInviteModal = ({
  fundraiserId,
  open,
  onClose,
}: {
  fundraiserId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const fundraiserInvitesClient = useFundraiserInvitesClient();
  const shareUrl = useAsync(async () => {
    if (!open) return null;

    // First, create a sharable link.
    try {
      const invite = await fundraiserInvitesClient.create({
        fundraiserId,
      });
      const shareUrl = new URL('/fundraisers/' + fundraiserId, window.origin);
      shareUrl.searchParams.set('invite', invite.id);

      return shareUrl.toString();
    }
    catch (err) {
      alert(err);
      return null;
    }
  }, [ fundraiserId, open ]);

  const modalBody = useMapping(() => {
    if (!open) return <></>

    if (!shareUrl.value) return <></>
    return <ParticipantInviteModalBody url={shareUrl.value} />
  }, [ open, shareUrl.value ]);

  return <div>
    <Modal enabled={open} setEnabled={() => onClose()}>
      {modalBody}
    </Modal>
  </div>
}

