import clsx from "clsx";
import { DonationQueryParams, useDonations } from "../../data/donation-service";
import { useMapping } from "../../hooks/use-mapping";
import { DonationList } from "../lists/donation-list";
import styles from './sections.module.scss';

export const DonationsSection = ({
  query,
}: {
  query: DonationQueryParams;
}) => {
  const donations = useDonations(query);

  const view = useMapping(() => {
    if (!donations.value) return <></>

    return <DonationList donations={donations.value.rows} />
  }, [ donations.value ]);

  return <section className={clsx(styles.section, styles.padding1)}>
    {view}
  </section>
}