import { useState } from 'react';
import { AddFundraiserModal } from '../modals/add-fundraiser-modal';
import styles from './icons.module.scss';
import clsx from 'clsx';

export const AddFundraiserIcon = () => {
  const [ open, setOpen ] = useState(false);

  return <div>
    <i onClick={() => setOpen(true)} className={clsx('material-icons', styles.raised, styles.round, styles.clickable)}>add</i>
    <AddFundraiserModal open={open} onClose={() => setOpen(false)} />
  </div>
}