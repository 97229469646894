import { useState } from "react";
import { useAccounts } from "../../data/account-service"
import { AccountList } from "../lists/account-list";
import styles from './pages.module.scss';

export const AdminAccountsPage = () => {
  const [ offset, setOffset ] = useState(0);
  const accounts = useAccounts({
    offset,
  });

  return <div className={styles.pageWrapper}>
    <div key='fundraiser-page' className={styles.page}>
      <AccountList accounts={accounts.value || []} />
    </div>
  </div>
}