import clsx from "clsx";
import { useParams, useSearchParams } from "react-router-dom";
import { Fundraiser, useFundraiser, useFundraiserPermissions } from "../../data/fundraiser-service";
import { useMapping } from "../../hooks/use-mapping";
import { FundraiserHeader } from "../headers/fundraiser-header";
import { NotFoundPage } from "./not-found-page";
import styles from './pages.module.scss';
import { AddContributorIcon } from "../icons/add-contributor-icon";
import { Modal } from "../modals/modal";
import { SimpleButton } from "../buttons/simple-button";
import { useAuth } from "../../hooks/use-auth";
import { useAccountProfile } from "../../data/profile-service";
import { ConfirmParticipantForm } from "../forms/confirm-participant-form";
import { ErrorLabel } from "../labels/error-label";
import { useEffect } from "react";
import useHttp from "../../hooks/use-http";

const MainView = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;  
}) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const http = useHttp();

  const onClose = () => {
    localStorage.removeItem('inviteLink');
    http.client.delete('/api/v1/participant-invite-tokens');
    setSearchParams(new URLSearchParams());
  }

  useEffect(() => {
    const invite = searchParams.get('invite');

    if (invite) {
      http.client.post('/api/v1/participant-invite-tokens', {
        fundraiserId: fundraiser.id,
        inviteId: invite,
      });
    }
  }, [ searchParams ]);

  const popup = useMapping(() => {
    const redirectStatus = searchParams.get('thanks');
    const invite = searchParams.get('invite');

    if (redirectStatus) {
      return <div className={clsx(styles.donationThanks, styles.flex, styles.center)}>
        <span>Your donation has been recorded.</span>
        <span>Thank you!</span>
        <SimpleButton onClick={onClose}>Close</SimpleButton>
      </div>
    }
    if (invite) {
      return <ConfirmParticipantForm fundraiserId={fundraiser.id} inviteId={invite} onClose={onClose} />
    }

    return null;
  }, [ searchParams ]);

  return <div className={styles.pageWrapper}>
    <div key='fundraiser-page' className={clsx(styles.page)}>
      <FundraiserHeader fundraiser={fundraiser} />
    </div>
    <Modal enabled={popup !== null} setEnabled={() => onClose()}>
      {popup || <></>}
    </Modal>
  </div>
}

export const FundraiserPage = () => {
  const {
    fundraiserId,
  } = useParams() as {
    fundraiserId: string;
  };
  const fundraiser = useFundraiser(fundraiserId);

  return useMapping(() => {
    if (fundraiser.loading) return <></>
    if (fundraiser.error) return <ErrorLabel error={fundraiser.error} />

    return <MainView fundraiser={fundraiser.value} />
  }, [ fundraiser.value ]);
}