import clsx from 'clsx';
import styles from './lists.module.scss';
import { Profile } from '../../data/profile-service';

const ProfileListItem = ({
  profile,
  onClick,
}: {
  profile: Profile;
  onClick?: () => void;
}) => {
  return <li className={clsx(styles.listItem, styles.clickable)} onClick={onClick}>
    <div className={styles.profileRow}>
      <div className={styles.smallPortrait}>
        <img src={`/api/v1/profiles/${profile.id}/portrait?cb=${new Date().getTime()}`} alt='This user has no profile picture.' />
      </div>
      <div className={clsx(styles.flex, styles.center)}>
        {profile.name}
      </div>
    </div>
  </li>
}

export const ProfileList = ({
  profiles,
  onClickProfile,
}: {
  profiles: Profile[];
  onClickProfile?: (x: Profile) => void;
}) => {
  const items = profiles.map(profile => {
    return <ProfileListItem profile={profile}
      onClick={onClickProfile ? () => onClickProfile(profile) : undefined}
    />
  });

  return <ul className={styles.list}>
    {items}
  </ul>
}