import { AxiosInstance } from "axios";
import { useEffect } from "react";
import { EventDelegate } from "../delegate";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useAuth } from "../hooks/use-auth";
import { defaultHttpClient } from "../hooks/use-http";

export type BroadcastInput = {
  fundraiserId: string;
  messageBody: string;
}

export enum BroadcastStatus {
  Pending = 'pending',
  Sent = 'sent',
}

export type Broadcast = {
  id: string;
  createdAt: string;
  updatedAt: string;
  fundraiserId: string;
  messageBody: string;
  status: BroadcastStatus;
  contactsReceived: number;
  contactsFailed: number;
}

export type BroadcastQueryParams = {
  fundraiserId?: string;
}

export type BroadcastPage = {
  rows: Broadcast[];
}

export class BroadcastsClient {
  onCreated = new EventDelegate<Broadcast>();
  onUpdated = new EventDelegate<Broadcast>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: BroadcastInput) => {
    const resp = await this.client.post('/api/v1/broadcasts', input);
    this.onCreated.trigger(resp.data);
    return resp.data;
  }

  find = async (query?: BroadcastQueryParams): Promise<BroadcastPage> => {
    const resp = await this.client.get('/api/v1/broadcasts', {
      params: query,
    });
    return resp.data;
  }

  get = async (broadcastId: string) => {
    const resp = await this.client.get(`/api/v1/broadcasts/${broadcastId}`);
    return resp.data;
  }
}

const broadcastsClient = new BroadcastsClient(defaultHttpClient);

export const useBroadcastsClient = () => {
  return broadcastsClient;
}

export const useBroadcasts = <A,>(query?: BroadcastQueryParams, asyncArgs?: AsyncArgs<A>) => {
  const broadcastsClient = useBroadcastsClient();
  const auth = useAuth();

  const task = useAsync(async () => {
    // Only query for broadcasts that the user is allowed to query for.
    // Non-authorized users should not be allowed to query for broadcasts.
    if (!auth.value) return { rows: [] };
    
    return await broadcastsClient.find(query);
  }, [ JSON.stringify(query), auth.value ], asyncArgs);

  useEffect(() => {
    return broadcastsClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}

export const useBroadcast = (broadcastId: string) => {
  const broadcastsClient = useBroadcastsClient();
  const task = useAsync(async () => {
    return await broadcastsClient.get(broadcastId);
  }, [ broadcastId ]);

  return task;
}