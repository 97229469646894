import clsx from 'clsx';
import { SimpleButton } from '../buttons/simple-button';
import styles from './pages.module.scss';
import { Link } from 'react-router-dom';
import { useFundraisers } from '../../data/fundraiser-service';
import { FundraiserList } from '../lists/fundraiser-list';
import { EventsListSection } from '../sections/events-list-section';

export const SearchPage = () => {
  const fundraisers = useFundraisers({
    isPublic: true,
  });

  return <div className={styles.pageWrapper}>
    <div key='search-page' className={clsx(styles.page)}>
      <FundraiserList adminLinks={false} fundraisers={fundraisers?.rows || []} />
    </div>
  </div>
}