import { BadRequestError, IssueCode } from "../../data/errors";
import { useMapping } from "../../hooks/use-mapping";

export const ErrorLabel = ({
  error,
}: {
  error?: Error | null;
}) => {
  const label = useMapping(() => {
    if (!error) return null;

    if (error instanceof BadRequestError) {
      for (const issue of error.issues) {
        if (issue.code === IssueCode.PaymentDeclined) return "Sorry! Payment was declined.";
        if (issue.code === IssueCode.InvalidValue) return `Invalid ${issue.field}`;
        if (issue.code === IssueCode.CardExpired) return `Sorry! Card is expired.`;
        console.log({ issue });
      }

      return error.issues.map(x => x.code).join('. ');
    }

    return error.message;
  }, [ error ]);

  return <span>{label}</span>
}