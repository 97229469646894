import { AxiosInstance } from "axios";
import { EventDelegate } from "../delegate";
import { defaultHttpClient } from "../hooks/use-http";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useEffect } from "react";

export type Profile = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  accountId: string;
  name: string;
  portraitSrc: string;
}

export type ProfileInput = {
  accountId: string;
  name: string;
}

export type ProfileQueryParams = {
  accountId?: string;
  fundraiserId?: string;
  limit?: number;
  name?: string;
}

export type ProfilePatch = {
  name?: string;
}

export type ProfilePage = {
  rows: Profile[];
}

export class ProfilesClient {
  onCreated = new EventDelegate<Profile>();
  onUpdated = new EventDelegate<Profile>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: ProfileInput) => {
    const resp = await this.client.post('/api/v1/profiles', input);
    this.onCreated.trigger(resp.data);
    return resp.data;
  }

  setPortrait = async (profileId: string, file: File): Promise<Profile> => {
    const form = new FormData();
    form.append('portrait', file);

    const resp = await this.client({
      method: 'POST',
      url: `/api/v1/profiles/${profileId}/portrait`,
      data: form,
    });

    this.onUpdated.trigger(resp.data);
    return resp.data;
  }

  find = async (query?: ProfileQueryParams): Promise<ProfilePage> => {
    const resp = await this.client.get('/api/v1/profiles', {
      params: query,
    });
    return resp.data;
  }

  get = async (profileId: string) => {
    const resp = await this.client.get(`/api/v1/profiles/${profileId}`);
    return resp.data;
  }

  update = async (profileId: string, patch: ProfilePatch): Promise<Profile> => {
    const resp = await this.client.patch(`/api/v1/profiles/${profileId}`, patch);
    this.onUpdated.trigger(resp.data);
    return resp.data;
  }
}

const profilesClient = new ProfilesClient(defaultHttpClient);

export const useProfilesClient = () => {
  return profilesClient;
}

export const useProfiles = <A,>(query?: ProfileQueryParams, asyncArgs?: AsyncArgs<A>) => {
  const profilesClient = useProfilesClient();
  const task = useAsync(async () => {
    return await profilesClient.find(query);
  }, [ JSON.stringify(query) ], asyncArgs);

  useEffect(() => {
    return profilesClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}

export const useProfile = (profileId: string) => {
  const profilesClient = useProfilesClient();
  const task = useAsync(async () => {
    return await profilesClient.get(profileId);
  }, [ profileId ]);

  return task;
}

export const useAccountProfile = (accountId: string) => {
  const profilesClient = useProfilesClient();
  const task = useAsync(async () => {
    const profiles = await profilesClient.find({
      accountId,
      limit: 1,
    });

    if (profiles.rows.length === 0) return null;
    return profiles.rows[0];
  }, [ accountId ]);

  return task;
}