import { useEffect, useState } from "react"
import useHttp from "../../hooks/use-http";
import { useMapping } from "../../hooks/use-mapping";

export const AdminFilesPage = () => {
  const http = useHttp();
  const [ files, setFiles ] = useState<any[]>([]);

  useEffect(() => {
    setFiles([]);

    const loop = async (files: any[], cursor?: string): Promise<void> => {
      const xs = await http.client.get('/api/v1/files', {
        params: {
          cursor,
          limit: 16,
        },
      }).then(r => r.data);

      const newFiles = [ ...files, ...xs.files ];
      setFiles(newFiles);

      console.log(newFiles.length);
      if (!xs.next) return;
      // await new Promise(f => setTimeout(f, 5000));
      return await loop(newFiles, xs.next);
    }

    loop([]);
  }, [ ]);

  const images = useMapping(() => {
    return files.map(file => {
      return <div style={{ width: '10rem', height: '10rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'grey', borderRadius: '1rem', }}>
        <img style={{ maxWidth: '10rem', maxHeight: '10rem' }} src={`/api/v1/files/${file.key}`} />
      </div>
    });
  }, [ files ]);

  return <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>
    {images}
  </div>
}