import { useMapping } from "../../hooks/use-mapping";
import { AddParticipantForm } from "../forms/add-participant-form";
import { Modal } from "./modal";

export const AddParticipantModal = ({
  fundraiserId,
  open,
  onClose,
}: {
  fundraiserId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const view = useMapping(() => {
    if (!open) return <></>

    return <AddParticipantForm fundraiserId={fundraiserId} onSubmit={() => onClose()} onCancel={onClose} />
  }, [ open ]);

  return <Modal enabled={open} setEnabled={() => onClose()} onClose={onClose}>
    {view}
  </Modal>
}