import clsx from "clsx";
import styles from './inputs.module.scss';
import { DetailedHTMLProps, FormEvent, HTMLAttributes, KeyboardEvent, TextareaHTMLAttributes, useState } from "react";

export const TextArea = ({
  value,
  onUpdate,
  ...props
}: {
  value?: string;
  onUpdate: (x: string) => void;
} & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => {
  const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
    // onUpdate(e.currentTarget.innerHTML);
    onUpdate(e.currentTarget.value);
  }

  return <textarea
    className={clsx(styles.textarea)}
    value={value}
    onChange={handleChange}
    rows={Math.max(2, value?.split('\n').length || 0)}
    {...props}
  />
}