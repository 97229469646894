import { useEffect, useRef } from 'react';
import styles from './modals.module.scss';
import clsx from 'clsx';

export type ModalProps = {
  children: JSX.Element[] | JSX.Element;
  enabled?: boolean;
  setEnabled: (b: boolean) => void;
  onClose?: () => void;
}

export const Modal = ({
  children,
  enabled,
  setEnabled,
  onClose,
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!enabled) return;

    const onClick = (e: globalThis.MouseEvent) => {
      if (!e.target) return;

      if (closeRef.current !== null && !closeRef.current.contains(e.target as any)) {
        setEnabled(false);
        onClose && onClose();
      }
      if (ref.current && !ref.current.contains(e.target as any)) {
        setEnabled(false);
        onClose && onClose();
      }
      console.log('window click: ', e);
      return false;
    }

    document.addEventListener('mousedown', onClick);
    return () => document.removeEventListener('mousedown', onClick)
  }, [ ref, closeRef, onClose, setEnabled ]);

  useEffect(() => {
    const overflow = document.body.style.overflow;
    if (!enabled) {
      document.body.style.overflow = 'scroll';
      return;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
    }
  }, [ enabled ]);

  return <>
    <div ref={closeRef} className={clsx(enabled ? null : styles.hidden, styles.modalView)} onClick={() => onClose && onClose()}>
      <div className={styles.modalContent} onClick={() => onClose && onClose()}>
        <div ref={ref} className={styles.modal} onClick={e => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  </>
}