import clsx from "clsx";
import { useState } from "react";
import { useCoachesClient } from "../../data/coach-service";
import { useProfilesClient, Profile } from "../../data/profile-service";
import { useAsync } from "../../hooks/use-async";
import { useMapping } from "../../hooks/use-mapping";
import { SimpleButton } from "../buttons/simple-button";
import { ProfileList } from "../lists/profile-list";
import styles from './forms.module.scss';

export const AddCoachForm = ({
  fundraiserId,
  onClose,
}: {
  fundraiserId: string;
  onClose: () => void;
}) => {
  const [ search, setSearch ] = useState<string | null>(null);
  const profilesClient = useProfilesClient();
  const [ profile, setProfile ] = useState<Profile | null>(null);
  const [ error, setError ] = useState<Error | null>(null);
  const coachesClient = useCoachesClient();

  const options = useAsync(async () => {
    return await profilesClient.find({
      name: search || undefined,
      limit: 16,
    });
  }, [ search ], {
    defer: true,
    default: null,
    debounce: true,
    delay: 250,
  });

  const addCoach = async () => {
    try {
      if (profile === null) throw new Error('Invalid Profile');

      await coachesClient.create({
        fundraiserId,
        profileId: profile.id,
      });
    }
    catch (err) {
      setError(err as Error);
    }
  }

  const profilesView = useMapping(() => {
    if (profile !== null) return <ProfileList profiles={[ profile ]} onClickProfile={() => setProfile(null)} />
    if (options.loading) return <></>
    if (options.error) return <></>
    if (options.value === null) {
      return <> </>
    }

    if (options.value.rows.length === 0) return <div className={clsx(styles.flex, styles.center)}>
      <span>Sorry! No profiles were found.</span>
    </div>

    return <>
      <ProfileList profiles={options.value.rows} onClickProfile={setProfile} />
    </>
  }, [ options.value, profile ]);

  return <div style={{ margin: '.5rem' }} className={clsx(styles.form, styles.flex, styles.gap2, styles.spaceBetween)}>
    <h1>
      Add a Coach
    </h1>
    <div className={clsx(styles.flex, styles.gap2, styles.spaceBetween, styles.reverseRow)}>
      <label>Profile Name</label>
      <input disabled={profile !== null} onChange={e => setSearch(e.target.value)} />
    </div>
    <div style={{ height: '30rem', overflow: 'scroll' }}>
      {profilesView}
    </div>
    <div style={{ display: 'flex', gap: '1rem' }}>
      <SimpleButton onClick={onClose}>Cancel</SimpleButton>
      <SimpleButton onClick={addCoach} disabled={profile === null}>Add</SimpleButton>
    </div>
  </div>
}
