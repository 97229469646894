import { useState } from "react";
import { FundraiserQueryParams, useFundraisers } from "../../data/fundraiser-service"
import { SimpleButton } from "../buttons/simple-button";
import { FundraiserList } from "../lists/fundraiser-list";
import { AddFundraiserModal } from "../modals/add-fundraiser-modal";
import styles from './sections.module.scss';
import { Auth, Scope, useAuth } from "../../hooks/use-auth";
import { useMapping } from "../../hooks/use-mapping";
import clsx from 'clsx';
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddFundraiserIcon } from "../icons/add-fundraiser-icon";
import { ParticipantQueryParams, useParticipants } from "../../data/participant-service";
import { ParticipantList } from "../lists/participant-list";

export const ParticipantFundraisersSection = ({
  auth,
  query={},
  showPaging=false,
}: {
  auth: Auth;
  query?: ParticipantQueryParams;
  showPaging?: boolean;
}) => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const navigate = useNavigate();
  const createdBefore = useMapping(() => {
    const value = searchParams.get('createdBefore');
    if (!value) return undefined;
    return value;
  }, [ searchParams ]);

  const limit = 8;
  const participants = useParticipants(query);
  const [ createOpen, setCreateOpen ] = useState(false);

  return <section className={styles.section}>
    <div className={clsx(styles.flex, styles.centerV)}>
      <h4 className={styles.sectionTitle}>Your Fundraisers (Click below to open)</h4>
    </div>
    <div className={clsx(styles.flex, styles.centerV)}>
      <div className={styles.margin1}>
        {participants.error?.message}
      </div>
    </div>
    <ParticipantList participants={participants.value?.rows || []} />
    <AddFundraiserModal open={createOpen} onClose={() => setCreateOpen(false)} />
  </section>
}