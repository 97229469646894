import clsx from 'clsx';
import { useState } from 'react';
import { AddParticipantModal } from '../modals/add-participant-modal';
import styles from './icons.module.scss';
import { AddContactModal } from '../modals/add-contact-modal';

export const AddContactIcon = ({
  participantId,
  fundraiserId,
}: {
  participantId?: string;
  fundraiserId: string;
}) => {
  const [ open, setOpen ] = useState(false);

  return <div>
    <i onClick={() => setOpen(true)} className={clsx('material-icons', styles.raised, styles.round, styles.clickable)}>person_add</i>
    <AddContactModal participantId={participantId} fundraiserId={fundraiserId} open={open} onClose={() => setOpen(false)} />
  </div>
}
