import styles from './pages.module.scss';

const policyHtml = `
<h1>Privacy Policies<span style="color: #215af9">.</span></h1>
<h4>Last updated April 11, 2023</h4>
<p>This privacy notice for Qwik Raise LLC (“Company”, “we”, “us”, or “our”) describes how and why we might collect, store, use, and/or share (“process”) your information when you use our services (“Services”), such as when you:</p>
<ul>
<li>Visit our website, or any website of ours that links to this privacy notice</li>
<li>Engage with us in other related ways, including any sales, marketing, or events</li>
</ul>
<p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at qwikraise@gmail.com.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
<h4><span style="color: #000000"><strong>Personal information you disclose to us</strong></span></h4>
<p><em><strong>In Short:</strong> We collect personal information that you provide us.</em></p>
<p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
<p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
<ul>
<li>Names</li>
<li>Phone numbers</li>
<li>Email addresses</li>
<li>Mailing addresses</li>
<li>Usernames</li>
<li>Passwords</li>
<li>Contact information</li>
<li>Debit/credit card numbers</li>
<li>Billing addresses</li>
</ul>
<p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
<p><strong>Payment Data.</strong> We may collect data necessary to process your payment if you make donations, such as your payment instrument number, and the security code associated with your payment instrument.</p>
<p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
<p><em><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em></p>
<p><strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong></p>
<ul>
<li>To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.</li>
<li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
<li>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
<li>To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.</li>
<li>To post testimonials. We post testimonials on our Services that may contain personal information.</li>
<li>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
<li>To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.</li>
<li>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
<li>To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.</li>
</ul>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
<p><em><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</em></p>
<p>We may need to share your personal information in the following situations.</p>
<ul>
<li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
<li><strong>Business Partners.</strong> We may share your information with our business partners to offer you certain products, services, or promotions.</li>
</ul>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
<p><em><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</em></p>
<p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
<p><em><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></p>
<p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
<p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
<p><em><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</em></p>
<p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
<p><em><strong>In Short:</strong> You may review, change, or terminate your account at any time.</em></p>
<p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section<strong> “HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”</strong> below.</p>
<p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
<p><strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section <strong>“HOW CAN YOU CONTACT US ABOUT THIS NOTICE?”</strong> below. You will then be removed from the marketing lists. However, we may still communicate with you &#8211; for example, to send you service-related messages that are necessary for the administration and use of your account to respond to service requests, or for other non-marketing purposes.</p>
<h4><span style="color: #000000">Account Information</span></h4>
<p>If you would at any time like to review or change the information in your account to terminate your account, you can:</p>
<ul>
<li>Log in to your account settings and update your user account.</li>
<li>Contact us using the contact information provided.</li>
</ul>
<p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
<p><strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.</p>
<p>If you have questions or comments about your privacy rights, you may email us at qwikraise@gmail.com</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>8. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
<p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
<p><em><strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></p>
<p>California Civil Code Section 1798.,83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information below.</p>
<p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>
<p><strong>CCPA Privacy Notice</strong></p>
<p>If you would at any time like to review or change the information in your account to terminate your account, you can:</p>
<p style="padding-left: 40px">(1) every individual who is in the State of California for other than a temporary or transitory purpose and<br />
(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</p>
<p>All other individuals are defined as “non-residents.”</p>
<p>If this definition of “resident” applies to you, we must adhere to certain rights and obligations regarding your personal information.</p>
<p><strong>How do we use and share your personal information?</strong></p>
<p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
<p>You may contact us byu email at qwikraise@gmail.com, or by referring to the contact details at the bottom of the document.</p>
<p>If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
<p><strong>Will your information be shared with anyone else?</strong></p>
<p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA.</p>
<p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be “selling” of your personal information.</p>
<p>Qwik Raise LLC has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Qwik Raise LLC will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>
<p><strong>Your rights with respect to your personal data</strong></p>
<p>Right to request deletion of teh data &#8211; Request to delete</p>
<p>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.</p>
<p>Right to be informed &#8211; Request to know</p>
<p>Depending on the circumstances, you have a right to know:</p>
<ul>
<li>whether we collect and use your personal information;</li>
<li>the categories of personal information that we collect;</li>
<li>the purposes for which the collected personal information is used;</li>
<li>whether we sell or share personal information to third parties;</li>
<li>the categories of third parties to whom the personal information was sold, shared, or disclosed for business purpose;</li>
<li>the business or commercial purpose for collecting, selling, or sharing personal information, and</li>
<li>the specific pieces of personal information we collected about you.</li>
</ul>
<p>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</p>
<p>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</p>
<p>We will not discriminate against you if you exercise your privacy rights.</p>
<p>Right to Limit Use and Disclosure of Sensitive Personal Information</p>
<p>We do not process consumer’s sensitive personal information.</p>
<p>Verification process</p>
<p>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have provided to us. We may also use other verification methods as the circumstances dictate.</p>
<p>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</p>
<p>Other privacy rights</p>
<ul>
<li>You may object to the processing of your personal information</li>
<li>You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.</li>
<li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</li>
<li>You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.</li>
</ul>
<p>To exercise these rights, you can contact us by email at qwikraise@gmail.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
<p><em><strong>In Short:</strong> Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.</em></p>
<p>California Civil Code Section 1798.,83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information below.</p>
<p>If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</p>
<p><strong>Virginia CDPA Privacy Notice</strong></p>
<p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
<p>“Consumer” means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.</p>
<p>“Personal data” means any information that is linked or reasonably linkable to an identified or identifiable natural person. “Personal data” does not include de-identified data or publicly available information.</p>
<p>“Sale of personal; data” means the exchange of personal data for monetary consideration.</p>
<p>If this definition “consumer” applies to you, we must adhere to certain rights and obligations regarding your personal data.</p>
<p>The information we collect, use, and disclose about you will vary depending on how you interact with Qwik Raise LLC and our Services.</p>
<p>Your rights with respect to your personal data</p>
<ul>
<li>Right to be informed whether or not we are processing your personal data</li>
<li>Right to access your personal data</li>
<li>Right to correct inaccuracies in your personal data</li>
<li>Right to request deletion of your personal data</li>
<li>Right to obtain a copy of the personal data you previously shared with us</li>
<li>Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects (“profiling”)</li>
</ul>
<p>Qwik Raise LLC has not sold any personal data to third parties for business or commercial purposes. Qwik Raise LLC will not sell personal data in the future belonging to website visitors, users, and other consumers.</p>
<p>Exercise your rights provided under the Virginia CDPA</p>
<p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
<p>You may contact us by email at qwikraise@gmail.com, or by referring to the contact details at the bottom of this document.</p>
<p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</p>
<p>Verification process</p>
<p>We may request that you provide additional information reasonably necessary to verify you and your consumer’s request. If you submit the request through an authorized agent, we may need to collect additional information to verify your identify before processing your request.</p>
<p>Upon receiving your request, we will respond without undue delay, but in all cases, with forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.</p>
<p>Right to appeal</p>
<p>If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at qwikraise@gmail.com. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to submit a complaint.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 30px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
<p><em><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with the relevant laws.</em></p>
<p>We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this policy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
</div><div class='devider devider_align_left  font_size_devider_18px' style='padding: 0px 0px 0px 0px ; max-width:auto; margin-top:0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; background:;'><span id="" class="anchor_link"></span><h3>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
<p>If you have questions or comments about this notice, you may email us at qwikraise@gmail.com or by post to:</p>
<p>Qwik Raise LLC<br />
304 Blythe Bridge Dr.<br />
Roanoke, TX 76262<br />
United States</p>
<p>This privacy policy was created using Termly’s Privacy Policy Generator.</p>
`;

export const PrivacyPolicyPage = () => {
  return <>
    <div className={styles.privacyPage} dangerouslySetInnerHTML={{ __html: policyHtml }}>
    </div>
  </>
}