import clsx from "clsx";
import { useState } from "react";
import { useMapping } from "../../hooks/use-mapping";
import { usePreferences } from "../../hooks/use-preferences";
import { ProfileForm } from "../forms/profile-form";
import { EditProfileSection } from "../sections/edit-profile-section";
import { TabSection } from "../sections/tab-section";
import styles from './pages.module.scss';
import { Auth, useAuth } from "../../hooks/use-auth";
import { ParticipantFundraisersSection } from "../sections/participant-fundraisers-section";
import { Profile } from "../../data/profile-service";
import { DonationsSection } from "../sections/donations-section";
import { DonationStatus } from "../../data/donation-service";

enum Tab {
  Profile = 'Profile',
}

const ProfileTab = ({
  auth,
  profile,
}: {
  auth: Auth;
  profile: Profile;
}) => {
  return <>
    <EditProfileSection />
    <ParticipantFundraisersSection query={{ profileId: profile.id }} auth={auth} />
    <h4 style={{ margin: '2rem' }}>Your Donations</h4>
    <DonationsSection query={{
      profileId: profile.id,
      statusIn: [
        DonationStatus.Pending,
        DonationStatus.Success,
      ],
    }} />
  </>
}

const TabbedPage = ({
  profile,
}: {
  profile: Profile;
}) => {
  const [ view, setView ] = useState(<></>);
  const auth = useAuth();

  const onTab = (tab: Tab) => {
    if (!auth.value) return <></>

    const newView = (() => {
      switch (tab) {
        case Tab.Profile:
          return <ProfileTab auth={auth.value} profile={profile} />
      }
    })();

    setView(newView);
  }
  return <div className={styles.pageWrapper}>
    <div className={clsx(styles.page)}>
      <TabSection
        defaultTab={Tab.Profile}
        tabs={Object.values(Tab)}
        onSelected={onTab}
      />
      {view}
    </div>
  </div>

}

export const CreateProfilePage = () => {
  return <div className={styles.pageWrapper}>
    <div className={clsx(styles.page)}>
      <ProfileForm onCreated={() => undefined} />
    </div>
  </div>
}

export const StandardHomePage = () => {
  const [ preferences ] = usePreferences();

  return useMapping(() => {
    if (!preferences.value) return <></>
    if (!preferences.value.profile) return <CreateProfilePage />
    return <TabbedPage profile={preferences.value.profile} />
  }, [ preferences.value ]);
}