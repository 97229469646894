import { AxiosInstance } from "axios";
import { EventDelegate } from "../delegate";
import { defaultHttpClient } from "../hooks/use-http";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useEffect } from "react";

export type FundraiserInviteAccept = {
  inviteId: string;
  profileId: string;
}

export type FundraiserInviteInput = {
  fundraiserId: string;
}

export interface FundraiserInvite {
  id: string;
}

export class FundraiserInvitesClient {
  constructor(
    private client: AxiosInstance,
  ) { }

  accept = async (accept: FundraiserInviteAccept) => {
    const resp = await this.client.put('/api/v1/fundraiser-invites/accept', accept);
    return resp.data;
  }

  create = async (input: FundraiserInviteInput) => {
    const resp = await this.client.post('/api/v1/fundraiser-invites', input);
    return resp.data;
  }
}

const fundraiserInvitesClient = new FundraiserInvitesClient(defaultHttpClient);

export const useFundraiserInvitesClient = () => {
  return fundraiserInvitesClient;
}