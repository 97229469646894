import clsx from 'clsx';
import { TabButton } from '../buttons/tab-button';
import styles from './sections.module.scss';
import { useEffect, useState } from 'react';

export const TabSection = <A,>({
  defaultTab,
  tabs,
  onSelected,
}: {
  defaultTab: A;
  tabs: A[];
  onSelected: (tab: A) => void;
}) => {
  const [ tab, setTab ] = useState<A>(defaultTab);
  const tabButtons = tabs.map(x => {
    return <TabButton active={x === tab} label={x as string} onClick={() => { setTab(x) }} />
  })

  useEffect(() => {
    onSelected(tab);
  }, [ tab ]);

  return <section className={clsx(styles.section, styles.tabSection)}>
    {tabButtons}
  </section>
}