import { useEffect, useState } from 'react';
import { TabButton } from '../buttons/tab-button';
import styles from './sections.module.scss';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { useMapping } from '../../hooks/use-mapping';

export enum FundraiserTab {
  Summary = 'summary',
  Participants = 'participants',
  // Contacts = 'contacts',
  // Broadcasts = 'broadcasts',
  Coaches = 'coaches',
  Analytics = 'analytics',
}

export const FundraiserTabSection = ({
  onSelection,
}: {
  onSelection: (x: FundraiserTab) => void;
}) => {
  const [ query, setQuery ] = useSearchParams();

  const tab: FundraiserTab = useMapping(() => {
    return Object.values(FundraiserTab).find(x => x === query.get('tab') as any) || FundraiserTab.Summary;
  }, [ query ]);

  const setTab = (tab: FundraiserTab) => {
    query.set('tab', tab);
    setQuery(query, {
      replace: true,
    });
  }

  useEffect(() => {
    onSelection(tab);
  }, [ tab ]);

    // <TabButton active={tab === FundraiserTab.Contacts} label='Contacts' onClick={() => setTab(FundraiserTab.Contacts)} />
    // <TabButton active={tab === FundraiserTab.Broadcasts} label='Broadcasts' onClick={() => setTab(FundraiserTab.Broadcasts)} />
  return <section className={clsx(styles.section, styles.tabSection)}>
    <TabButton active={tab === FundraiserTab.Summary} label='Summary' onClick={() => setTab(FundraiserTab.Summary)} />
    <TabButton active={tab === FundraiserTab.Participants} label='Participants' onClick={() => setTab(FundraiserTab.Participants)} />
    <TabButton active={tab === FundraiserTab.Coaches} label='Coaches' onClick={() => setTab(FundraiserTab.Coaches)} />
    <TabButton active={tab === FundraiserTab.Analytics} label='Analytics' onClick={() => setTab(FundraiserTab.Analytics)} />
  </section>
}