import clsx from "clsx";
import styles from './pages.module.scss';
import { Link, useParams } from "react-router-dom";
import { Icon } from "../icons/icon";
import { OptionList } from "../lists/options-list";
import { useMapping } from "../../hooks/use-mapping";
import { Subheading } from "../../headings/sub-heading";
import { ProfilePortraitInput } from "../inputs/profile-portrait-input";
import { usePreferences } from "../../hooks/use-preferences";
import { SetProfileNameForm } from "../forms/set-profile-name-form";

const TabsRollout = () => {
  const {
    setting,
  } = useParams();

  return <div className={clsx(styles.pageRollout, setting && styles.settingActive)}>
    <OptionList options={[
      {
        icon: 'person',
        label: 'Profile',
        link: '/settings/profile',
      },
    ]} />
  </div>
}

export const ProfileRollout = () => {
  const [ preferences ] = usePreferences();

  const view = useMapping(() => {
    if (!preferences.value?.profile) return <>
      <span>No profile available</span>
    </>
    return <>
      <div className={styles.center}>
        <span>Update your profile picture?</span>
        <ProfilePortraitInput profile={preferences.value.profile} />
        <SetProfileNameForm profile={preferences.value.profile} />
      </div>
    </>
  }, [ preferences.value ]);

  return <div>
    <Subheading label='Public Profile' />
    <div className={styles.settingSubview}>
      {view}
    </div>
  </div>
}

export const SettingsPage = () => {
  const {
    setting,
  } = useParams();

  const settingView = useMapping(() => {
    switch (setting) {
      case 'profile':
        return <ProfileRollout />
    }
  }, [ setting ]);

  return <div className={styles.settingsPage}>
    <h1>Settings</h1>
    <div className={styles.settingsLayout}>
      <TabsRollout />
      <div className={styles.settingView}>
        {settingView}
      </div>
    </div>
  </div>
}