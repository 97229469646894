import { useState } from "react";
import { AccountFeature, useAccountsClient } from "../../data/account-service";
import { useMapping } from "../../hooks/use-mapping";
import { ToggleInput } from "../inputs/toggle-input";
import styles from './lists.module.scss';

export const AccountFeatureList = ({
  features,
}: {
  features: AccountFeature[];  
}) => {
  const accountsClient = useAccountsClient();
  const [ error, setError ] = useState<Error | null>(null);

  const items = useMapping(() => {
    return features.map(feature => {
      const setEnabled = (enabled: boolean) => {
        accountsClient.setFeature({
          ...feature,
          enabled,
        }).catch(setError);
      }

      return <li key={`account-feature:${feature.featureFlag}`} className={styles.listItem}>
        <span>{feature.featureFlag}</span>
        <ToggleInput label='Enabled' enabled={feature.enabled} setEnabled={setEnabled} />
      </li>
    });
  }, [ features ]);

  return <ul key={`account-feature-list`} className={styles.list}>
    <span>{error?.message || ''}</span>
    {items}
  </ul>
}