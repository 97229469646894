import * as auth0 from '@auth0/auth0-react';
import { Auth0Lock, Auth0LockPasswordless } from 'auth0-lock';
import axios from "axios";
import React from "react";
import { useAsync } from './use-async';
import { useMapping } from './use-mapping';
import { ErrorLabel } from '../ui/labels/error-label';

export type AuthConfig = {
  clientId: string;
  domain: string;
  audience?: string;
}

export const Auth0Context = React.createContext<typeof Auth0LockPasswordless | null>(null as any);

export type Auth0ContextProps = {
  children: JSX.Element | JSX.Element[];
  config: AuthConfig;
};

export const loadAuthConfig = async () => {
  return await axios.get('/api/auth/config')
    .then(r => r.data as AuthConfig)
    // .then(config => new Auth0Lock( 
    //   config.clientId,
    //   config.domain,
    //   {
    //     auth: {
    //       redirect: false,
    //       sso: true,
    //       params: {
    //         scope: "openid profile email id_token",
    //       },
    //       audience: config.audience,
    //     },
    //   },
    // ));
}

export const Auth0Provider = ({ children, config }: Auth0ContextProps) => {
  return <auth0.Auth0Provider
    domain={config.domain}
    clientId={config.clientId}
    authorizationParams={{
      sso: true,
      redirect_uri: window.location.origin,
      audience: config.audience,
    }}
  >
    {children}
  </auth0.Auth0Provider>
}

const useAuth0 = () => React.useContext(Auth0Context);
export default useAuth0;