import clsx from "clsx";
import { Fundraiser, useFundraisersClient } from "../../data/fundraiser-service";
import { useAsync } from "../../hooks/use-async";
import { Icon } from "../icons/icon";
import { TextArea } from "../inputs/textarea";
import styles from './forms.module.scss';
import { useState } from "react";
import { useMapping } from "../../hooks/use-mapping";
import { ErrorLabel } from "../labels/error-label";

const formatDate = (value?: string | null) => {
  if (!value) return undefined;
  return new Date(value).toISOString().substring(0, 10);
}

export const SetFundraiserDatesForm = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const fundraisersClient = useFundraisersClient();
  const [ startsAt, setStartsAt ] = useState(formatDate(fundraiser.startsAt));
  const [ endsAt, setEndsAt ] = useState(formatDate(fundraiser.endsAt));

  const update = useAsync(async () => {
    const updated = await fundraisersClient.update(fundraiser.id, {
      startsAt: startsAt ? new Date(startsAt).toISOString() : undefined,
      endsAt: endsAt ? new Date(endsAt).toISOString() : undefined,
    });
    setStartsAt(formatDate(updated.startsAt));
    setEndsAt(formatDate(updated.endsAt));
  }, [ fundraiser.id, startsAt, endsAt ], {
    defer: true,
    default: null,
  });

  const icons = useMapping(() => {
    if (!startsAt && !endsAt) return null;
    if ((startsAt === formatDate(fundraiser.startsAt) && (endsAt === formatDate(fundraiser.endsAt)))) return null;

    return <>
      <button disabled={update.loading} type='button' className={styles.iconButton} onClick={() => { setStartsAt(formatDate(fundraiser.startsAt)); setEndsAt(formatDate(fundraiser.endsAt)) }} >
        <Icon icon='undo' />
      </button>
      <button disabled={update.loading} type='submit' className={styles.iconButton} onClick={() => update.trigger()} >
        <Icon icon='check' />
      </button>
    </>
  }, [ fundraiser.startsAt, fundraiser.endsAt, startsAt, endsAt, update.loading ]);

  return <form className={clsx(styles.flatForm, styles.gap1)} action="javascript:void(0);" onSubmit={() => update.trigger()}>
    <ErrorLabel error={update.error} />
    <div className={clsx(styles.flex, styles.flexWrap, styles.gap4, styles.center)}>
      <div className={clsx(styles.flex, styles.gap1)}>
        <label>Starts At</label>
        <input type='date' value={startsAt} onChange={e => setStartsAt(e.currentTarget.value) } />
      </div>
      <div className={clsx(styles.flex, styles.gap1)}>
        <label>Ends At</label>
        <input type='date' value={endsAt} placeholder={endsAt} onChange={e => setEndsAt(e.currentTarget.value) } />
      </div>
    </div>
    <div className={styles.flex}>
      {icons}
    </div>
  </form>
}