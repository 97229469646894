import { AxiosInstance } from "axios";
import { useAsync } from "../hooks/use-async";
import { defaultHttpClient } from "../hooks/use-http";
import { EventDelegate } from "../delegate";
import { useEffect } from "react";

export enum FeatureFlag {
  ReadAccounts = 'read:accounts',
  WriteFundraisers = 'write:fundraisers',
}

export type Account = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  email: string;
  emailVerified: boolean;
}

export interface AccountFeature {
  accountId: string;
  featureFlag: FeatureFlag | string;
  enabled: boolean;
}

export type AccountQueryParams = {
  offset?: number;
  access?: string; 
  accessSubject?: string;
}

export class AccountsClient {
  onFeatureUpdated = new EventDelegate<AccountFeature>();

  constructor(
    private httpClient: AxiosInstance,
  ) { }

  find = async (queryParams: AccountQueryParams): Promise<Account[]> => {
    const resp = await this.httpClient.get('/api/v1/accounts', {
      params: queryParams,
    });
    return resp.data;
  }

  setFeature = async (feature: AccountFeature) => {
    await this.httpClient.post(`/api/v1/accounts/${feature.accountId}/features`, feature);
    this.onFeatureUpdated.trigger(feature);
  }

  getFeatures = async (accountId: string): Promise<AccountFeature[]> => {
    const resp = await this.httpClient.get(`/api/v1/accounts/${accountId}/features`);
    return resp.data;
  }
}

const accountsClient = new AccountsClient(defaultHttpClient);

export const useAccountsClient = () => {
  return accountsClient;
}

export const useAccounts = (queryParams: AccountQueryParams) => {
  const accountsClient = useAccountsClient();
  return useAsync(async () => accountsClient.find(queryParams), [ JSON.stringify(queryParams) ]);
}

export const useAccountFeatures = (accountId: string) => {
  const accountsClient = useAccountsClient();
  const task = useAsync(async () => accountsClient.getFeatures(accountId), [ accountId ]);

  useEffect(() => {
    return accountsClient.onFeatureUpdated.listen(() => task.trigger());
  }, [ accountsClient.onFeatureUpdated ])

  return task;
}