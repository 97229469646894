import { DependencyList, useEffect, useLayoutEffect, useState } from "react"

export const useMapping = <A,>(mapping: () => A, deps: DependencyList | undefined) => {
  const [ value, setValue ] = useState(() => mapping());
  const [ updates, setUpdates ] = useState(() => 0);

  useEffect(() => {
    setUpdates(x => x + 1);
  }, deps);

  useEffect(() => {
    if (updates <= 0) return;
    setValue(() => mapping());
  }, [ updates ]); // This prevents a duplicate render of the component using this.

  return value;
}