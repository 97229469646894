import { Link } from 'react-router-dom';
import styles from './pages.module.scss';
import { SimpleButton } from '../buttons/simple-button';
import { useAuth } from '../../hooks/use-auth';

export const LandingPage = () => {
  const auth = useAuth();

  return <div className={styles.pageWrapper}>
    <div className={styles.splitHeader}>
      <div className={styles.splitHeaderBox}>
        <h1>The Fast, Efficient Way to Raise Money.</h1>
        <div className={styles.infoButtons}>
          <Link to='https://qwikraise.com'>
            <SimpleButton>Learn More</SimpleButton>
          </Link>
        </div>
      </div>
      <div className={styles.splitHeaderBox}>
        <div className={styles.headerImgGrid}>
          <img src='https://qwikraise.com/wp-content/uploads/2023/05/Image-h1-1.jpg' />
          <img src='https://qwikraise.com/wp-content/uploads/2023/05/image-h2-1.jpg' />
          <img src='https://qwikraise.com/wp-content/uploads/2023/05/Image-h3-1-e1684962849718.jpg' />
        </div>
      </div>
    </div>
  </div>
}