import { useCallback, useState } from "react";
import { SimpleButton } from "./simple-button";
import { Modal } from "../modals/modal";
import { AddDonationForm } from "../forms/add-donation-form";
import { useMapping } from "../../hooks/use-mapping";

export const DonateButton = ({
  fundraiserId,
  participantId,
}: {
  fundraiserId: string;
  participantId?: string;
}) => {
  const [ open, setOpen ] = useState(false);

  const view = useMapping(() => {
    if (!open) return <></>
    return <AddDonationForm
      onClose={() => setOpen(false)}
      fundraiserId={fundraiserId}
      participantId={participantId}
    />
  }, [ open, fundraiserId ]);

  return <div>
    <SimpleButton onClick={() => setOpen(true)}>Donate</SimpleButton>
    <Modal enabled={open} setEnabled={setOpen}>
      {view}
    </Modal>
  </div>
}