import clsx from 'clsx';
import { FormEvent, useState } from 'react';
import { useContactsClient } from '../../data/contact-service';
import { SimpleButton } from '../buttons/simple-button';
import styles from './forms.module.scss';

export const AddContactForm = ({
  onSubmit,
  onCancel,
  participantId,
  fundraiserId,
}: {
  onSubmit: () => void;
  onCancel: () => void;
  participantId?: string;
  fundraiserId: string;
}) => {
  const contactsClient = useContactsClient();
  const [ error, setError ] = useState<Error | null>(null);
  const submit = (e: FormEvent<HTMLFormElement>) => (async () => {
    setError(null);

    const targets = (e.target as any).elements;
    const fullName = targets.fullName.value?.trim();
    const phoneNumber = targets.phoneNumber.value?.trim();
    const email = targets.email.value?.trim();

    if (!fullName) throw new Error('Please enter a valid full name.');

    await contactsClient.create({
      participantId,
      fundraiserId,
      fullName,
      phoneNumber,
      email,
    });

    const shareMsg = {
      text: 'Test invite!',
      url: new URL('/fundraisers/' + fundraiserId, window.origin).toString(),
    };
    if (navigator.canShare && navigator.canShare(shareMsg)) {
      navigator.share(shareMsg);
    }

    onSubmit();
  })().catch(setError);

  const handleCancel = (e: any) => {
    e.preventDefault();
    onCancel();
    return false;
  }

  return <div className={clsx(styles.form, styles.spaceBetween)}>
    <h2>Contact Information</h2>
    {error?.message || ''}
    <form className={clsx(styles.flex, styles.gap2, styles.spaceBetween)} action="javascript:void(0);" onSubmit={submit}>
      <label>Contact Name</label>
      <input autoFocus={true} tabIndex={0} name='fullName' />
      <label>Phone Number</label>
      <input name="phoneNumber" />
      <label>Email</label>
      <input name='email' />

      <div className={clsx(styles.flex, styles.gap2)}>
        <SimpleButton type="submit">Submit</SimpleButton>
        <SimpleButton onClick={handleCancel}>Cancel</SimpleButton>
      </div>
    </form>
  </div>
}