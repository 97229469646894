import { useEffect, useState } from 'react';
import { EventsListSection } from '../sections/events-list-section';
import { TabSection } from '../sections/tab-section';
import styles from './pages.module.scss';
import { AdminTab, AdminTabSection } from '../sections/admin-tab-section';
import { AccountsListSection } from '../sections/accounts-list-section';
import { Auth, useAuth } from '../../hooks/use-auth';
import { useMapping } from '../../hooks/use-mapping';

const AccountsView = () => {
  const auth = useAuth();

  return useMapping(() => {
    if (!auth.value) return <></>

    return <AccountsListSection query={{
      access: 'read',
      accessSubject: auth.value.subject,
    }} />
  }, [ auth.value ]);
}

export const AdminPage = ({
  auth,
}: {
  auth: Auth;
}) => {
  const [ view, setView ] = useState<JSX.Element>(<></>);

  const onTabSelected = (tab: AdminTab) => {
    const newView = (() => {
      switch (tab) {
        case AdminTab.Events:
          return <EventsListSection auth={auth} />

        case AdminTab.Accounts:
          return <AccountsView />
      }
    })();

    setView(newView);
  }

  return <div className={styles.pageWrapper}>
    <div key='admin-page' className={styles.page}>
      <AdminTabSection onSelection={onTabSelected} />
      {view}
    </div>
  </div>
}