import { AxiosInstance } from "axios";
import { EventDelegate } from "../delegate";
import { defaultHttpClient } from "../hooks/use-http";
import { AsyncArgs, useAsync } from "../hooks/use-async";
import { useEffect } from "react";
import { useAuth } from "../hooks/use-auth";
import { useParticipantsClient } from "./participant-service";

export type Contact = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  fundraiserId: string;
  participantId: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
}

export type ContactInput = {
  fundraiserId: string;
  participantId?: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
}

export type ContactQueryParams = {
  participantId?: string;
  fundraiserId?: string;
  limit?: number;
}

export type ContactPage = {
  rows: Contact[];
}

export class ContactsClient {
  onCreated = new EventDelegate<Contact>();
  onUpdated = new EventDelegate<Contact>();

  constructor(
    private client: AxiosInstance,
  ) { }

  create = async (input: ContactInput) => {
    const resp = await this.client.post('/api/v1/contacts', input);
    this.onCreated.trigger(resp.data);
    return resp.data;
  }

  find = async (query?: ContactQueryParams): Promise<ContactPage> => {
    const resp = await this.client.get('/api/v1/contacts', {
      params: query,
    });
    return resp.data;
  }

  count = async (query?: ContactQueryParams): Promise<number> => {
    const resp = await this.client.get('/api/v1/contacts/count', {
      params: query,
    });
    return resp.data;
  }

  get = async (contactId: string) => {
    const resp = await this.client.get(`/api/v1/contacts/${contactId}`);
    return resp.data;
  }
}

const contactsClient = new ContactsClient(defaultHttpClient);

export const useContactsClient = () => {
  return contactsClient;
}

export const useContacts = <A,>(query?: ContactQueryParams, asyncArgs?: AsyncArgs<A>) => {
  const contactsClient = useContactsClient();
  const auth = useAuth();

  const task = useAsync(async () => {
    // Only query for contacts that the user is allowed to query for.
    // Non-authorized users should not be allowed to query for contacts.
    if (!auth.value) return { rows: [] };
    
    return await contactsClient.find(query);
  }, [ JSON.stringify(query), auth.value ], asyncArgs);

  useEffect(() => {
    return contactsClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}

export const useContactsCount = (query?: ContactQueryParams) => {
  const contactsClient = useContactsClient();

  const task = useAsync(async () => contactsClient.count(query), [ JSON.stringify(query) ]);

  useEffect(() => {
    return contactsClient.onCreated.listen(() => task.trigger());
  }, [ contactsClient ]);
  useEffect(() => {
    return contactsClient.onUpdated.listen(() => task.trigger());
  }, [ contactsClient ]);

  return task;
}

export const useContact = (contactId: string) => {
  const contactsClient = useContactsClient();
  const task = useAsync(async () => {
    return await contactsClient.get(contactId);
  }, [ contactId ]);

  return task;
}

export const useParticipantContacts = (participantId: string) => {
  const participantsClient = useParticipantsClient();
  const contactsClient = useContactsClient();
  const auth = useAuth();

  const task = useAsync(async () => {
    if (!auth.value) return null;

    // Only query for contacts that the user is allowed to query for.
    // Non-authorized users should not be allowed to query for contacts.
    if (!auth.value) return { rows: [] };
      
    return await contactsClient.find({
      participantId,
    });
  }, [ participantId, auth.value ]);

  useEffect(() => {
    return contactsClient.onCreated.listen(() => {
      task.trigger();
    });
  }, [ ]);

  return task;
}