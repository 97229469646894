import clsx from "clsx";
import { Link } from "react-router-dom";
import { Fundraiser, useFundraiserPermissions } from "../../data/fundraiser-service";
import { useParticipants } from "../../data/participant-service";
import { useMapping } from "../../hooks/use-mapping";
import { SimpleButton } from "../buttons/simple-button";
import { AddParticipantIcon } from "../icons/add-participant-icon";
import { AddParticipantLinkIcon } from "../icons/add-participant-link-icon";
import { AddParticipantQRIcon } from "../icons/add-participant-qr-icon";
import { ParticipantList } from "../lists/participant-list";
import styles from './sections.module.scss';

const FundraiserActionIcons = ({
  fundraiserId,
}: {
  fundraiserId: string;
}) => {
  const fundraiserPermissions = useFundraiserPermissions({
    fundraiserId,
  });

  const actionIcons = useMapping(() => {
    if (!fundraiserPermissions.value) return <></>

    const icons: JSX.Element[] = [];

    // Ability for admins to add participants.
    if (fundraiserPermissions.value.rows.find(x => x.access === 'write')) {
      icons.push(<>
        <AddParticipantIcon fundraiserId={fundraiserId} />
      </>);
      icons.push(
        <AddParticipantLinkIcon fundraiserId={fundraiserId} />
      );
      icons.push(
        <AddParticipantQRIcon fundraiserId={fundraiserId} />
      );
    }
    // Ability for coaches (etc) to add participants.
    else if (fundraiserPermissions.value.rows.find(x => x.access === 'participants:write')) {
      icons.push(<>
        <AddParticipantIcon fundraiserId={fundraiserId} />
      </>);
    }

    return <>{icons}</>
  }, [ fundraiserPermissions.value ]);

  return <section className={clsx(styles.section, styles.flex, styles.padding1, styles.gap1)}>
    {actionIcons}
  </section>
}

export const ParticipantsSection = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const participants = useParticipants({
    fundraiserId: fundraiser.id,
    limit: 1024,
  });

  const participantsView = useMapping(() => {
    if (!participants.value) return <></>

    return <ParticipantList participants={participants.value.rows} showBanners={false} />
  }, [ participants.value ]);

  return <section>
    <div className={styles.center}>
      <h4>Participants for {fundraiser.title}</h4>
    </div>
    <FundraiserActionIcons fundraiserId={fundraiser.id} />
    <div style={{ marginLeft: '1rem' }}>
      <a href={`/api/v1/fundraisers/${fundraiser.id}/participants-csv`} download='participants.csv'>
        <SimpleButton>Download CSV</SimpleButton>
      </a>
    </div>
    {participantsView}
  </section>
}