import clsx from "clsx";
import { Fundraiser, useFundraisersClient } from "../../data/fundraiser-service";
import { useAsync } from "../../hooks/use-async";
import { Icon } from "../icons/icon";
import { TextArea } from "../inputs/textarea";
import styles from './forms.module.scss';
import { useState } from "react";
import { useMapping } from "../../hooks/use-mapping";
import { ErrorLabel } from "../labels/error-label";

export const SetFundraiserDescriptionForm = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const fundraisersClient = useFundraisersClient();
  console.log(fundraiser);
  const [ description, setDescription ] = useState(fundraiser.description);

  const update = useAsync(async () => {
    await fundraisersClient.update(fundraiser.id, {
      description,
    });
  }, [ fundraiser.id ], {
    defer: true,
    default: null,
  });

  const icons = useMapping(() => {
    if (description === fundraiser.description) return null;

    return <>
      <button disabled={update.loading} type='button' className={styles.iconButton} onClick={() => setDescription(fundraiser.description)} >
        <Icon icon='undo' />
      </button>
      <button disabled={update.loading} type='submit' className={styles.iconButton} onClick={() => update.trigger()} >
        <Icon icon='check' />
      </button>
    </>
  }, [ description, update.loading ]);

  return <form className={clsx(styles.flatForm, styles.gap1)} action="javascript:void(0);" onSubmit={() => update.trigger()}>
    <h4>Fundraiser Description</h4>
    <ErrorLabel error={update.error} />
    <TextArea 
      name='description'
      disabled={update.loading}
      value={description}
      placeholder={description}
      onUpdate={setDescription}
      rows={Math.max(1, description?.split('\n').length || 0)}
      tabIndex={0}
    />
    <div className={styles.flex}>
      {icons}
    </div>
  </form>
}