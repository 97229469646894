import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Coach, useCoaches } from '../../data/coach-service';
import { useContacts } from '../../data/contact-service';
import { useFundraiser } from '../../data/fundraiser-service';
import { useProfile } from '../../data/profile-service';
import { useMapping } from '../../hooks/use-mapping';
import { SimpleButton } from '../buttons/simple-button';
import { AddCoachForm } from '../forms/add-coach-form';
import { ContactList } from '../lists/contact-list';
import { Modal } from '../modals/modal';
import { BroadcastsSection } from '../sections/broadcasts-section';
import { FundraiserAnalyticsSection } from '../sections/fundraiser-analytics-section';
import { FundraiserSummarySection } from '../sections/fundraiser-summary-section';
import { FundraiserTab, FundraiserTabSection } from '../sections/fundraiser-tab-section';
import { ParticipantsSection } from '../sections/participants-section';
import styles from './pages.module.scss';

const CoachLI = ({
  coach,
}: {
  coach: Coach;
}) => {
  const profile = useProfile(coach.profileId);

  return <li>
    {profile.value?.name}
  </li>
}

const CoachesView = ({
  fundraiserId,
}: {
  fundraiserId: string;
}) => {
  const coaches = useCoaches({
    fundraiserId,
  });
  const [ openAdd, setOpenAdd ] = useState(false);

  const modalView = useMapping(() => {
    if (!openAdd) return <></>

    return <AddCoachForm fundraiserId={fundraiserId} onClose={() => setOpenAdd(false)} />
  }, [ openAdd ]);

  const coachItems = useMapping(() => {
    if (!coaches.value) return <></>

    return coaches.value.rows.map(coach => {
      return <CoachLI coach={coach} />
    });
  }, [ coaches.value ]);

  return <div style={{ height: '100vh' }}>
    <Modal enabled={openAdd} setEnabled={setOpenAdd}>
      {modalView}
    </Modal>
    <div style={{ margin: '1rem' }}>
      <SimpleButton onClick={() => setOpenAdd(true)}>Add Coach</SimpleButton>
    </div>
    <div style={{ margin: '1rem' }}>
      <ul>
        {coachItems}
      </ul>
    </div>
  </div>
}

const SummaryView = () => {
  const {
    fundraiserId,
  } = useParams();
  const fundraiser = useFundraiser(fundraiserId || '');

  return useMapping(() => {
    if (!fundraiser.value) return <></>

    return <FundraiserSummarySection fundraiser={fundraiser.value} />
  }, [ fundraiser.value ]);
}

const AnalyticsView = () => {
  const {
    fundraiserId,
  } = useParams();
  const fundraiser = useFundraiser(fundraiserId || '');
  return <FundraiserAnalyticsSection fundraiser={fundraiser.value || {} as any} />
}

const ParticipantsView = () => {
  const {
    fundraiserId,
  } = useParams() as { fundraiserId: string };
  const fundraiser = useFundraiser(fundraiserId || '');

  return useMapping(() => {
    if (!fundraiser.value) return <></>

    return <>
      <ParticipantsSection fundraiser={fundraiser.value || {} as any} />
    </>
  }, [ fundraiser.value ]);
}

const BroadcastsView = () => {
  const {
    fundraiserId,
  } = useParams() as { fundraiserId: string };

  return <BroadcastsSection fundraiserId={fundraiserId} />
}

const ContactsView = ({
  fundraiserId,
}: {
  fundraiserId: string;  
}) => {
  const contacts = useContacts({
    fundraiserId,
  });

  return <ContactList contacts={contacts.value?.rows || []} />
}

export const FundraiserSettingsPage = () => {
  const [ view, setView ] = useState<JSX.Element>(<></>);
  const {
    fundraiserId,
  } = useParams() as { fundraiserId: string };
  const fundraiser = useFundraiser(fundraiserId);

  /*

  Admin wants a button to send out emails for fundraiser.

  table with
  | participant name | contact count | raised amount for fundraiser |


  Possible CSV upload for coaches.

  */

  const onTabSelected = (tab: FundraiserTab) => {
    const newView = (() => {
      switch (tab) {
        case FundraiserTab.Summary:
          return <SummaryView />

        case FundraiserTab.Analytics:
          return <AnalyticsView />

        // case FundraiserTab.Contacts:
        //   return <ContactsView fundraiserId={fundraiserId} />

        case FundraiserTab.Participants:
          return <ParticipantsView />

        case FundraiserTab.Coaches:
          return <CoachesView fundraiserId={fundraiserId} />

        // case FundraiserTab.Broadcasts:
        //   return <BroadcastsView />
      }
    })();

    setView(newView);
  }

  const wrapped = useMapping(() => {
    if (fundraiser.error) return <>{fundraiser.error.message}</>
    if (!fundraiser.value) return <></>

    return <>
      <FundraiserTabSection onSelection={onTabSelected} />
      {view}
    </>
  }, [ view, fundraiser.loading, fundraiser.value ]);

  return <div className={styles.pageWrapper}>
    <div key='fundraiser-page' className={styles.page}>
      {wrapped}
    </div>
  </div>
}