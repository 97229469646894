import styles from './inputs.module.scss';
import clsx from 'clsx';

export const ToggleInput = ({
  enabled,
  setEnabled,
  label,
}: {
  enabled: boolean;
  setEnabled: (x: boolean) => void;
  label: string;
}) => {

  return <div className={styles.toggleContainer}>
    <div>{label}</div>
    <div className={clsx(styles.toggle, enabled && styles.toggleOn)} onClick={() => setEnabled(!enabled)}>
      <div className={clsx(styles.toggleSlider, enabled && styles.toggleSliderOn)}>
        {enabled ? 'True' : 'False'}
      </div>
    </div>
  </div>
}