import styles from './buttons.module.scss';
import clsx from 'clsx';

export const TabButton = ({
  label,
  active=false,
  onClick,
}: {
  label: string;
  active?: boolean;
  onClick?: () => void;
}) => {
  return <button disabled={active} onClick={onClick} className={clsx(styles.tab, active ? styles.activeTab : null)}>{label}</button>
}