import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAccountFeatures } from "../../data/account-service";
import { AccountFeatureList } from "../lists/account-feature-list";
import styles from './pages.module.scss';

export const AdminAccountPage = () => {
  const {
    accountId,
  } = useParams() as {
    accountId: string;
  };
  const [ offset, setOffset ] = useState(0);
  const features = useAccountFeatures(accountId);

  return <div className={styles.pageWrapper}>
    <div key='admin-account-page' className={styles.page}>
      <AccountFeatureList features={features.value || []} />
    </div>
  </div>
}