import clsx from 'clsx';
import { useContributions } from '../../data/contribution-service';
import { Fundraiser, FundraiserPatch, FundraiserStatus, useFundraisersClient } from '../../data/fundraiser-service';
import { SimpleButton } from '../buttons/simple-button';
import { BannerInput } from '../inputs/banner-input';
import { ContributionList } from '../lists/contributions-list';
import styles from './sections.module.scss';
import { ToggleInput } from '../inputs/toggle-input';
import { useState } from 'react';
import { useMapping } from '../../hooks/use-mapping';
import { PriceLabel } from '../labels/price-label';
import { FundraiserProgressBar } from '../analytics/fundraiser-progress-bar';
import { SetFundraiserGoalForm } from '../forms/set-fundraiser-goal-form';
import { DonationsSection } from './donations-section';
import { SetFundraiserDescriptionForm } from '../forms/set-fundraiser-description-form';
import { SetFundraiserDatesForm } from '../forms/set-fundraiser-dates';
import { SetFundraiserParticipantGoalForm } from '../forms/set-fundraiser-participant-goal';
import { DonationStatus } from '../../data/donation-service';

const DonationHistoryPanel = ({
  fundraiserId,
}: {
  fundraiserId: string;  
}) => {
  const contributions = useContributions({
    fundraiserId,
  })

  return <section className={clsx(styles.section, styles.fullWidth)}>
    <ContributionList contributions={contributions} />
  </section>
}

export const FundraiserSummarySection = ({
  fundraiser,
}: {
  fundraiser: Fundraiser;
}) => {
  const fundraisersClient = useFundraisersClient();
  const [ error, setError ] = useState<Error | null>(null);

  const update = (patch: FundraiserPatch) => {
    fundraisersClient.update(fundraiser.id, patch)
      .catch(setError);
  }

  const setIsPublic = (isPublic: boolean) => update({ isPublic });
  const setStatusOpen = (x: boolean) => {
    x ? update({ status: FundraiserStatus.Open })
      : update({ status: FundraiserStatus.Closed });
  }

  const toggleStatus = useMapping(() => {
    switch (fundraiser.status) {
      case FundraiserStatus.Pending:
      case FundraiserStatus.Closed:
        return <ToggleInput label={"Closed"} enabled={false} setEnabled={setStatusOpen} />
      case FundraiserStatus.Open:
        return <ToggleInput label={"Open"} enabled={true} setEnabled={setStatusOpen} />
    }
  }, [ fundraiser.status ]);

  return <section className={clsx(styles.section, styles.gap1)}>
    <div className={clsx(styles.center, styles.flex)}>
      <h1>{fundraiser.title}</h1>
    </div>
    <div className={clsx(styles.flex, styles.columnar, styles.center)}>
      <h4>Banner</h4>
      <BannerInput fundraiser={fundraiser} />
    </div>
    <div className={clsx(styles.center, styles.flex)}>
      <ToggleInput label={"Public"} enabled={fundraiser.isPublic} setEnabled={setIsPublic} />
      {toggleStatus}
    </div>
    <div className={clsx(styles.marginTop, styles.flex, styles.center)}>
      <h4>Campaign Details</h4>
    </div>
    <FundraiserProgressBar fundraiser={fundraiser} />
    <div className={clsx(styles.center, styles.gap2, styles.borderBottom)}>
      <div className={clsx(styles.center, styles.columnar)}>
        <h4>Pending</h4>
        <PriceLabel price={fundraiser.pendingDonations} />
      </div>
      <div className={clsx(styles.center, styles.columnar)}>
        <h4>Raised</h4>
        <PriceLabel price={fundraiser.donationsTotal} />
      </div>
      <div className={clsx(styles.center, styles.columnar)}>
        <h4>Goal</h4>
        <PriceLabel price={fundraiser.donationsGoal} />
      </div>
    </div>
    <div className={clsx(styles.center, styles.padding1, styles.borderBottom)}>
      <h4>Details</h4>
      <SetFundraiserDescriptionForm fundraiser={fundraiser} />
      <SetFundraiserGoalForm fundraiser={fundraiser} />
      <SetFundraiserParticipantGoalForm fundraiser={fundraiser} />
      <SetFundraiserDatesForm fundraiser={fundraiser} />
    </div>
    <div className={clsx(styles.center, styles.padding1, styles.borderBottom)}>
      <h4>Donation History</h4>
      <DonationsSection query={{
        fundraiserId: fundraiser.id,
        statusIn: [
          DonationStatus.Pending,
          DonationStatus.Success,
        ],
      }} />
    </div>
  </section>
}
