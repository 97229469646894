import clsx from 'clsx';
import { Fundraiser, useFundraiser } from "../../data/fundraiser-service";
import { Participant } from "../../data/participant-service";
import { Profile, useProfile } from "../../data/profile-service";
import { useMapping } from '../../hooks/use-mapping';
import { FundraiserList } from "../lists/fundraiser-list";
import styles from './sections.module.scss';

const LoadedView = ({
  participant,
  profile,
  fundraiser,
}: {
  participant: Participant;
  profile: Profile;
  fundraiser: Fundraiser;
}) => {
  return <section className={styles.section}>
    <div className={clsx(styles.fullWidth, styles.flex, styles.center, styles.columnar, styles.gap0, styles.gapTop)}>
      <div className={clsx(styles.columnar, styles.center)}>
        <h4>{profile.name}</h4>
        <h6>Participant in, {fundraiser.title}</h6>
      </div>
    </div>
    <FundraiserList adminLinks={true} fundraisers={[ fundraiser ]} contributionsParticipantId={participant.id} />
  </section>
}

export const ParticipantSection = ({
  participant,
}: {
  participant: Participant;
}) => {
  const profile = useProfile(participant.profileId);
  const fundraiser = useFundraiser(participant.fundraiserId);

  return useMapping(() => {
    if (!fundraiser.value) return <></>

    return <LoadedView
      participant={participant}
      profile={profile.value || {} as any}
      fundraiser={fundraiser.value}
    />
  }, [ fundraiser.loading, participant, profile.value ]);
}